import { Search, HelpCircle, Compass, ChevronRight, Check, TriangleAlert } from "lucide-react"
import { HomeSidebar } from './HomeSidebar';

import pdfimg from '../assets/icons/pdf.svg';
import outlookimg from '../assets/icons/outlook.svg';
import autoimg from '../assets/icons/auto.svg';
import homeimg from '../assets/icons/home.svg';

export function PastRuns() {
  const zapHistory = [
    {
      status: "Failed",
      apps: ["pdf reader", "email processor", "home"],
      name: "Delos Workflow",
      date: "Sep 11, 2024 01:21:24 pm",
      tasks: 4,
    },
    {
      status: "Failed",
      apps: ["acrobat", "outlook", "auto"],
      name: "Auto Policy Automation",
      date: "Sep 11, 2024 01:20:21 pm",
      tasks: 4,
    },
    {
      status: "Success",
      apps: ["outlook", "acrobat"],
      name: "Policy Checking",
      date: "Sep 11, 2024 01:11:43 pm",
      tasks: 1,
    },
    {
      status: "Success",
      apps: ["outlook", "acrobat", "home"],
      name: "Dwelling Policy Automation",
      date: "Sep 11, 2024 01:09:48 pm",
      tasks: 1,
    },
    {
        status: "Success",
        apps: ["outlook", "acrobat"],
        name: "Quote Generation Workflow",
        date: "Sep 11, 2024 01:09:48 pm",
        tasks: 1,
      },
      {
        status: "Failed",
        apps: ["outlook", "acrobat"],
        name: "Policy Checking",
        date: "Sep 11, 2024 01:09:48 pm",
        tasks: 1
    }
  ]

  const getAppIcon = (appName: string) => {
    switch (appName.toLowerCase()) {
      case 'pdf reader':
      case 'acrobat':
        return <img src={pdfimg} alt="PDF" className="h-5 w-5" />;
      case 'email processor':
      case 'outlook':
        return <img src={outlookimg} alt="Email" className="h-5 w-5" />;
      case 'auto':
        return <img src={autoimg} alt="Auto" className="h-5 w-5" />;
      case 'home':
        return <img src={homeimg} alt="Home" className="h-5 w-5" />;
      default:
        return <div className="h-5 w-5 bg-gray-300 rounded-sm" />;
    }
  }

  return (
    <div className="flex">
      <HomeSidebar />
      <div className="flex-1 min-h-screen bg-white">
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-end items-center h-16">
              <div className="flex items-center space-x-4">
                <button className="p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-md flex items-center">
                  <Search className="h-6 w-6 mr-2" />
                  <span className="text-sm text-gray-600">Search</span>
                </button>
                <button className="p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-md flex items-center">
                  <HelpCircle className="h-6 w-6 mr-2" />
                  <span className="text-sm text-gray-600">Help</span>
                </button>
                <button className="p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-md flex items-center">
                  <Compass className="h-6 w-6 mr-2" />
                  <span className="text-sm text-gray-600">Explore</span>
                </button>
              </div>
            </div>
          </div>
        </header>

        {/* Main content */}
        <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-left">Past Runs</h1>
          <ul className="space-y-4">
            {zapHistory.map((zap, index) => (
              <li key={index} className="bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
                <div className="px-4 py-5 sm:px-6 grid grid-cols-12 gap-4 items-center">
                  <div className="col-span-1">
                    <input type="checkbox" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                  </div>

                  {/* Status component */}
                  <div className="col-span-2 max-w-[120px]">
                    {zap.status === "Failed" ? (
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-red-600 flex items-center justify-center">
                          <TriangleAlert className="h-4 w-4 text-white" strokeWidth={2} />
                        </div>
                        <span className="text-sm font-bold text-gray-700 ml-2">Failed</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center">
                          <Check className="h-4 w-4 text-white" strokeWidth={3} />
                        </div>
                        <span className="text-sm font-bold text-gray-700 ml-2">Success</span>
                      </div>
                    )}
                  </div>

                  {/* App icons */}
                  <div className="col-span-3 max-w-[180px]">
                    <div className="flex items-center space-x-2">
                      {zap.apps.map((app, appIndex) => (
                        <div key={appIndex} className="flex items-center justify-center w-7 h-7 bg-white border border-gray-200 rounded-sm">
                          {getAppIcon(app)}
                        </div>
                      ))}
                      {zap.apps.length > 1 && (
                        <div className="flex items-center justify-center w-5 h-5 bg-gray-200 rounded-full text-xs font-bold text-gray-600">
                          +{zap.apps.length - 1}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Workflow name */}
                  <div className="col-span-3 max-w-[200px]">
                    <p className="text-sm font-bold text-gray-900 truncate">{zap.name}</p>
                    <p className="text-xs text-gray-500">{zap.date}</p>
                  </div>

                  {/* Right side components */}
                  <div className="col-span-3 flex items-center space-x-4 justify-end">
                    <span className="px-2 py-1 text-xs font-semibold rounded-full bg-gray-100 text-gray-800">
                      {zap.tasks} {zap.tasks === 1 ? 'task' : 'tasks'}
                    </span>
                    <img
                      src="https://res.cloudinary.com/subframe/image/upload/v1725373200/uploads/299/p7tnzanxwdjlibw4tdxj.png"
                      alt="User Avatar"
                      className="rounded-full"
                      width={32}
                      height={32}
                    />
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </main>
      </div>
    </div>
  )
}
