import React, { useState, useCallback, useMemo, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  Node,
  Edge,
  Connection,
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  Controls,
  NodeProps,
  EdgeProps,
  ConnectionMode,
  Handle,
  Position,
  NodeChange,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Button } from "./ui/button";
import { Plus } from "lucide-react";

import { toast } from 'react-hot-toast';
import { useNodeApi } from '../hooks/useNodeApi';

import { NodeType, FilterType, WorkflowNodeData } from '../types/workflowTypes';
import { AddNodeSidebar } from './NodeSidebar';
import NodeConfigDialog from './NodeConfigDialog';

import { getWorkflowNodeIcon } from '../lib/iconUtils';
import { nodeTypeConfigs } from '../config/nodeTypeConfigs';
import { filterInputForBackend } from '../lib/nodeUtils';
import { HomeSidebar } from './HomeSidebar';
import { withAuthInfo } from "@propelauth/react";

const convertToReactFlowNodes = (nodes: WorkflowNodeData[]): Node<WorkflowNodeData>[] => {
  return nodes.map((node, index) => ({
    id: node.id,
    type: 'customNode',
    position: { x: 100, y: index * 200 },
    data: node,
  }));
};

const initialNodes: Node<WorkflowNodeData>[] = convertToReactFlowNodes([
  {
    id: "1", type: "trigger", title: "Input Entry", input: {
      "email": "test@gmail.com",
      "name": "John Doe"
    }
  },
  {
    id: "2", type: "extract", title: "Extract Data", input: {
      "email": "test@gmail.com",
      "name": "John Doe"
    }
  },
  {
    id: "3", type: "verify", title: "Verify Data", input: {
      "email": "test@gmail.com",
      "name": "John Doe"
    }
  },
  {
    id: "4", type: "fill", title: "Fill Excel", input: {
      "email": "test@gmail.com",
      "name": "John Doe"
    }
  },
]);

const CustomNodeComponent: React.FC<NodeProps<WorkflowNodeData> & { handleNodeClick: (event: React.MouseEvent, node: Node<WorkflowNodeData>) => void }> = ({ data, id, handleNodeClick }) => {
  const IconOrSrc = getWorkflowNodeIcon(data.type);

  return (
    <div
      className="bg-white rounded-lg shadow-md p-4 border border-gray-200 w-80 cursor-pointer"
      onClick={(event) => handleNodeClick(event, { id, data } as Node<WorkflowNodeData>)}
    >
      <Handle type="target" position={Position.Top} className="w-3 h-3" />
      <div className="flex items-center space-x-3">
        <div className="w-10 h-10 flex items-center justify-center rounded-md bg-gray-100 flex-shrink-0">
          {typeof IconOrSrc === 'string' ? (
            <img src={IconOrSrc} alt={data.title} className="w-6 h-6 object-contain" />
          ) : (
            <IconOrSrc className="w-6 h-6 text-gray-600" />
          )}
        </div>
        <div className="flex-grow">
          <div className="text-sm font-medium text-gray-900">{data.id}. {data.title}</div>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} className="w-3 h-3" />
    </div>
  );
};

interface CustomEdgeProps extends EdgeProps {
  addNodeBetween: (sourceId: string, targetId: string) => void;
}

const CustomEdge: React.FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  addNodeBetween
}) => {
  const edgePath = `M${sourceX},${sourceY} L${targetX},${targetY}`;
  const foreignObjectSize = 24;
  const foreignObjectX = (sourceX + targetX) / 2 - foreignObjectSize / 2;
  const foreignObjectY = (sourceY + targetY) / 2 - foreignObjectSize / 2;

  return (
    <>
      <path
        id={id}
        className="custom-edge-path"
        d={edgePath}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={foreignObjectX}
        y={foreignObjectY}
        className="custom-edge-button-container"
      >
        <div
          className="custom-edge-button"
          onClick={(event) => {
            event.stopPropagation();
            addNodeBetween(source, target);
          }}
        >
          <Plus className="w-4 h-4 text-white" />
        </div>
      </foreignObject>
    </>
  );
};

const ExtraEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
}) => {
  const edgePath = `M${sourceX},${sourceY} L${sourceX},${sourceY + 50}`;
  const buttonY = sourceY + 25;

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        strokeWidth={3}
        stroke="#60a5fa"
      />
      <foreignObject
        width={24}
        height={24}
        x={sourceX - 12}
        y={buttonY - 12}
        className="overflow-visible"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div
          className="flex items-center justify-center w-6 h-6 bg-blue-500 rounded-full cursor-pointer shadow-md"
          onClick={() => {/* Add node logic here */ }}
        >
          <Plus className="w-4 h-4 text-white" />
        </div>
      </foreignObject>
    </>
  );
};

export const WorkflowBuilder = withAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<WorkflowNodeData>(initialNodes);
  const [isAddNodeSheetOpen, setIsAddNodeSheetOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<Node<WorkflowNodeData> | null>(null);
  const availableNodeTypes: NodeType[] = [
    { id: "trigger", name: "Trigger" },
    { id: "extract", name: "Extract" },
    { id: "verify", name: "Verify" },
    { id: "auto", name: "Auto" },
    { id: "home", name: "Home" },
    { id: "outlook", name: "Outlook" },
    { id: "docs", name: "Documents" },
    { id: "portal", name: "Portal" },
  ];
  const builtInTools = ['filter', 'formatter', 'paths', 'delay', 'code', 'email'];
  const [history] = useState<string[]>([]);
  const [isNodeConfigOpen, setIsNodeConfigOpen] = useState(false);
  const [pendingNodeConnection, setPendingNodeConnection] = useState<{ sourceId: string, targetId: string } | null>(null);

  const initialEdges: Edge[] = [
    { id: 'e1-2', source: '1', target: '2', type: 'customEdge' },
    { id: 'e2-3', source: '2', target: '3', type: 'customEdge' },
    { id: 'e3-4', source: '3', target: '4', type: 'customEdge' },
  ];

  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [extraEdge, setExtraEdge] = useState<Edge | null>(null);

  useEffect(() => {
    if (nodes.length > 0) {
      const lastNode = nodes[nodes.length - 1];
      setExtraEdge({
        id: `e${lastNode.id}-extra`,
        source: lastNode.id,
        target: `${lastNode.id}-extra`,
        type: 'extraEdge',
      });
    } else {
      setExtraEdge(null);
    }
  }, [nodes]);

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      setEdges((eds) => addEdge({ ...params, type: 'customEdge' }, eds));
    },
    [setEdges]
  );

  const openAddNodeSidebar = useCallback(
    (sourceId: string, targetId: string) => {
      setPendingNodeConnection({ sourceId, targetId });
      setIsAddNodeSheetOpen(true);
    },
    [setIsAddNodeSheetOpen]
  );

  const addNodeAfterSelection = useCallback(
    (nodeType: string) => {
      if (!pendingNodeConnection) return;

      const { sourceId, targetId } = pendingNodeConnection;
      const sourceNode = nodes.find((n) => n.id === sourceId);
      const targetNode = nodes.find((n) => n.id === targetId);

      if (!sourceNode || !targetNode) return;

      const newNodeId = (nodes.length + 1).toString();
      const newNode: Node<WorkflowNodeData> = {
        id: newNodeId,
        type: 'customNode',
        position: {
          x: (sourceNode.position.x + targetNode.position.x) / 2,
          y: (sourceNode.position.y + targetNode.position.y) / 2,
        },
        data: {
          id: newNodeId,
          type: nodeType,
          title: `New ${nodeType} Node`,
          input: {},
          updated_at: new Date().toISOString(),
        },
      };

      setNodes((nds) => {
        const updatedNodes = [...nds, newNode];
        return updatedNodes.sort((a, b) => a.position.y - b.position.y);
      });

      setEdges((eds) => {
        const edgeToRemove = eds.find(
          (e) => (e.source === sourceId && e.target === targetId) || (e.source === targetId && e.target === sourceId)
        );

        if (!edgeToRemove) return eds;

        const filteredEdges = eds.filter((e) => e !== edgeToRemove);

        const newEdges = [
          { id: `${sourceId}-${newNodeId}`, source: sourceId, target: newNodeId, type: 'customEdge' },
          { id: `${newNodeId}-${targetId}`, source: newNodeId, target: targetId, type: 'customEdge' },
        ];

        return [...filteredEdges, ...newEdges];
      });

      setPendingNodeConnection(null);
      setIsAddNodeSheetOpen(false);
    },
    [nodes, setNodes, setEdges, pendingNodeConnection]
  );

  const handleNodeClick = useCallback((event: React.MouseEvent, node: Node<WorkflowNodeData>) => {
    event.preventDefault();
    setSelectedNode(node);
    setIsNodeConfigOpen(true);
  }, []);

  const { saveNode } = useNodeApi(accessToken);

  const handleNodeConfigSave = async (updatedNode: WorkflowNodeData) => {
    try {
      const nodeConfig = nodeTypeConfigs[updatedNode.type];
      if (!nodeConfig) {
        throw new Error(`No configuration found for node type: ${updatedNode.type}`);
      }

      const filteredInput = filterInputForBackend(updatedNode.input, nodeConfig.fields);

      await saveNode({
        type: updatedNode.type,
        _id: updatedNode.id,
        input: filteredInput,
        updated_at: new Date().toISOString(),
      });
      setNodes((nds) =>
        nds.map((node) =>
          node.id === updatedNode.id ? { ...node, data: { ...node.data, ...updatedNode, input: filteredInput } } : node
        )
      );
      setIsNodeConfigOpen(false);
      toast.success('Node updated successfully');
    } catch (err) {
      toast.error('Failed to update node');
    }
  };

  const nodeTypes = useMemo(() => ({
    customNode: (props: NodeProps<WorkflowNodeData>) => (
      <CustomNodeComponent
        {...props}
        handleNodeClick={handleNodeClick}
      />
    ),
  }), [handleNodeClick]);

  const edgeTypes = useMemo(() => ({
    customEdge: (props: EdgeProps) => (
      <CustomEdge
        {...props}
        addNodeBetween={openAddNodeSidebar}
      />
    ),
    extraEdge: ExtraEdge,
  }), [openAddNodeSidebar]);

  const handleNodesChange = useCallback(
    (changes: NodeChange[]) => {
      onNodesChange(changes);

      setNodes((nds) => {
        const updatedNodes = nds.slice().sort((a, b) => a.position.y - b.position.y);
        return updatedNodes;
      });

      setEdges((eds) => {
        return eds.map((edge) => {
          const sourceNode = nodes.find((n) => n.id === edge.source);
          const targetNode = nodes.find((n) => n.id === edge.target);
          if (sourceNode && targetNode) {
            return {
              ...edge,
              sourceX: sourceNode.position.x,
              sourceY: sourceNode.position.y,
              targetX: targetNode.position.x,
              targetY: targetNode.position.y,
            };
          }
          return edge;
        });
      });
    },
    [nodes, onNodesChange, setNodes, setEdges]
  );

  const addNodeAtEnd = useCallback(
    (nodeType: string) => {
      const newNodeId = (nodes.length + 1).toString();
      const lastNode = nodes[nodes.length - 1];
      const newNodePosition = {
        x: lastNode ? lastNode.position.x : 100,
        y: lastNode ? lastNode.position.y + 200 : 100,
      };

      const newNode: Node<WorkflowNodeData> = {
        id: newNodeId,
        type: 'customNode',
        position: newNodePosition,
        data: {
          id: newNodeId,
          type: nodeType,
          title: `New ${nodeType} Node`,
          input: {},
          updated_at: new Date().toISOString(),
        },
      };

      setNodes((nds) => [...nds, newNode]);

      if (lastNode) {
        setEdges((eds) => [
          ...eds,
          {
            id: `e${lastNode.id}-${newNodeId}`,
            source: lastNode.id,
            target: newNodeId,
            type: 'customEdge',
          },
        ]);
      }

      setIsAddNodeSheetOpen(false);
    },
    [nodes, setNodes, setEdges]
  );

  const openAddNodeSidebarForNewNode = useCallback(() => {
    setPendingNodeConnection(null);
    setIsAddNodeSheetOpen(true);
  }, []);

  return (
    <div className="flex">
      <HomeSidebar />
      <div className="relative w-full h-screen overflow-hidden bg-gray-50">
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={[...edges, ...(extraEdge ? [extraEdge] : [])]}
            onNodesChange={handleNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={handleNodeClick}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            connectionMode={ConnectionMode.Loose}
            fitView
          >
            <Background />
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>

        <Button
          className="absolute bottom-4 right-4"
          onClick={openAddNodeSidebarForNewNode}
        >
          <Plus className="mr-2 h-4 w-4" /> Add Node
        </Button>

        <AddNodeSidebar
          isOpen={isAddNodeSheetOpen}
          onOpenChange={setIsAddNodeSheetOpen}
          availableNodeTypes={availableNodeTypes}
          builtInTools={builtInTools}
          history={history}
          onSelectNodeType={pendingNodeConnection ? addNodeAfterSelection : addNodeAtEnd}
        />

        <NodeConfigDialog
          open={isNodeConfigOpen}
          onOpenChange={setIsNodeConfigOpen}
          node={selectedNode?.data || null}
          onSave={handleNodeConfigSave}
          accessToken={accessToken}
        />
      </div>
    </div>
  );
})

export type { NodeType, FilterType, WorkflowNodeData };
