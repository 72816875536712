import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import { CallLogResponse } from "../../services/api";
import { HomeSidebarV1 } from "../HomeSidebarV1";
import { Search, ChevronRight } from "lucide-react";
import toast from 'react-hot-toast';
import { Card, CardContent } from "../ui/card";
import { Input } from "../ui/input";
import { withRequiredAuthInfo } from "@propelauth/react";
import axios from 'axios';

interface VoiceDetailResultsProps {
    callLog: CallLogResponse | null;
}

const POLL_INTERVAL = 5000;
const POLL_TIMEOUT = 300000;
export const VoiceDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
    const navigate = useNavigate();
    const { id: callLogId } = useParams<{ id: string }>();
    const { fetchCallLogById } = useNodeApi(accessToken);
    const [callLog, setCallLog] = useState<CallLogResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isOffline, setIsOffline] = useState(false);
    const lastPollTimeRef = useRef(0);

    const loadCompareLog = useCallback(async (bypassTimeCheck = false) => {
        if (!callLogId) {
            setError('No comparison ID provided.');
            setIsLoading(false);
            return;
        }

        const now = Date.now();
        if (!bypassTimeCheck && now - lastPollTimeRef.current < POLL_INTERVAL) {
            return;
        }

        let retries = 0;
        const maxRetries = Math.ceil(POLL_TIMEOUT / POLL_INTERVAL);

        const attemptFetch = async (): Promise<void> => {
            try {
                const result = await fetchCallLogById(callLogId);
                if (result) {
                    if (result.call_status && result.call_status != "Initiated" && result.call_status != "Ongoing") {
                        setCallLog(result);
                        setIsLoading(false);
                    } else if (retries < maxRetries) {
                        retries++;
                        setTimeout(() => attemptFetch(), POLL_INTERVAL);
                    } else {
                        setError('Comparison is taking longer than expected. Please check back later.');
                        setIsLoading(false);
                    }
                }
                lastPollTimeRef.current = now;
                if (isOffline) {
                    setIsOffline(false);
                    toast.success("Connection restored. Compare log updated.");
                }
            } catch (error) {
                console.error("Failed to fetch compare log:", error);
                if (retries > 0 && axios.isAxiosError(error) && error.status === 404) {
                    navigate('/404');
                    return;
                }
                if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    if (!isOffline) {
                        setIsOffline(true);
                        toast.error("Failed to fetch comparison results. Please try again.");
                    }
                    setError('Failed to fetch comparison results. Please try again.');
                    setIsLoading(false);
                }
            }
        };

        attemptFetch();
    }, [callLogId, fetchCallLogById, isOffline, navigate]);

    const loadCompareLogRef = useRef(loadCompareLog);

    useEffect(() => {
        loadCompareLogRef.current = loadCompareLog;
    }, [loadCompareLog]);

    useEffect(() => {
        loadCompareLogRef.current(true);
    }, []);

    return (
        <div className="flex h-screen overflow-hidden">
            <HomeSidebarV1 />
            <div className="flex-1 flex flex-col overflow-hidden ml-56">
                <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
                    <div className="max-w-6xl mx-auto px-2">
                        {isLoading ? (
                            <VoiceDetailSkeleton />
                        ) : error ? (
                            <VoiceDetailError error={error} />
                        ) : (
                            <VoiceDetailResults callLog={callLog} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
})

function VoiceDetailResults({ callLog: voiceDetail }: VoiceDetailResultsProps) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    if (!voiceDetail || !voiceDetail.call_status || voiceDetail.call_status == "Initiated" || voiceDetail.call_status == "Ongoing") {
        return <VoiceDetailSkeleton />;
    }

    const parseTranscript = (transcript: string) => {
        return transcript.split('\n').map(line => {
            const [speaker, ...contentParts] = line.split(':');
            const content = contentParts.join(':').trim();
            const isAI = speaker.toLowerCase().includes('agent');
            return { isAI, content };
        }).filter(item => item.content);
    };

    const filteredTranscript = voiceDetail.details?.transcript
        ? parseTranscript(voiceDetail.details.transcript).filter(item =>
            item.content.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    return (
        <div>
            <div className="flex items-center mb-4">
                <div className="flex items-center text-md text-gray-500">
                    <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/voice')}>Voice AI</span>
                    <ChevronRight className="h-4 w-4 mx-2" />
                    <span className="font-medium text-gray-900">Details</span>
                </div>
            </div>
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                    <div className="mb-6">
                        <h2 className="text-lg font-semibold mb-4">Summary</h2>
                        <div className="bg-white border border-gray-200 rounded-lg p-4">
                            <p className="text-sm text-gray-700">
                                {voiceDetail.call_summary || "No summary available"}
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-semibold">Transcript</h2>
                            <div className="relative flex-grow max-w-fit ml-4">
                                <Input
                                    type="text"
                                    placeholder="Search transcript..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-10 pr-4 py-2 w-full text-sm"
                                />
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                            </div>
                        </div>
                        <div className="space-y-4">
                            {filteredTranscript.map((item, index) => (
                                <Card key={index} className={`bg-gray-50 ${item.isAI ? 'ml-0' : 'ml-auto'}`}>
                                    <CardContent className="p-3">
                                        <div className="flex items-center mb-1">
                                            <span className={`text-sm font-semibold ${item.isAI ? 'text-blue-600' : 'text-green-600'}`}>
                                                {item.isAI ? "Further AI" : voiceDetail.callee_name || "User"}
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-700">{item.content}</p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function VoiceDetailSkeleton() {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <div className="h-8 w-64 bg-gray-200 rounded mb-6 animate-pulse"></div>
                <div className="h-64 w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
        </div>
    );
}

function VoiceDetailError({ error }: { error: string }) {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <h1 className="text-xl font-semibold mb-6">Error</h1>
                <p className="text-red-500 mb-4">{error}</p>
            </div>
        </div>
    );
}
