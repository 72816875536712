import React from 'react';
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { FieldConfig, MappingField } from '../config/nodeTypeConfigs';
import { Button } from "./ui/button";
import { FieldValue, FileTemplate } from '../config/nodeTypeConfigs';

interface DynamicNodeFieldsProps {
  fields: FieldConfig[];
  values: Record<string, FieldValue>;
  onChange: (key: string, value: FieldValue) => void;
}

const DynamicNodeFields: React.FC<DynamicNodeFieldsProps> = ({ fields, values, onChange }) => {
  const toStringValue = (value: FieldValue): string => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'string') return value;
    if (Array.isArray(value)) return JSON.stringify(value);
    if (typeof value === 'object') {
      if ('filename' in value && 'url' in value) {
        return (value as FileTemplate).filename;
      }
      return JSON.stringify(value);
    }
    return String(value);
  };

  const handleFileChange = async (key: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await fileToBase64(file);
      const fileTemplate: FileTemplate = {
        filename: file.name,
        url: base64,
      };
      onChange(key, fileTemplate);
    }
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleMappingChange = (key: string, index: number, field: 'input' | 'data', value: string) => {
    const currentMapping = (values[key] as MappingField[]) || [];
    const newMapping = [...currentMapping];
    newMapping[index] = { ...newMapping[index], [field]: value };
    onChange(key, newMapping);
  };

  const addMapping = (key: string) => {
    const currentMapping = (values[key] as MappingField[]) || [];
    onChange(key, [...currentMapping, { input: '', data: '' }]);
  };

  const removeMapping = (key: string, index: number) => {
    const currentMapping = (values[key] as MappingField[]) || [];
    onChange(key, currentMapping.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4">
      {fields.map((field) => (
        <div key={field.key} className="space-y-2">
          <Label htmlFor={field.key} className="text-sm font-medium">
            {field.label}
          </Label>
          {field.type === 'input' && (
            <Input
              id={field.key}
              value={toStringValue(values[field.key])}
              onChange={(e) => onChange(field.key, e.target.value)}
              className="w-full"
            />
          )}
          {field.type === 'select' && (
            <Select
              value={field.valueMap
                ? Object.keys(field.valueMap).find(key => field.valueMap?.[key] === values[field.key]) ?? ''
                : toStringValue(values[field.key])}
              onValueChange={(value) => {
                const actualValue = field.valueMap?.[value] ?? value;
                onChange(field.key, actualValue);
                if (field.key === 'extractSchema') {
                  onChange('extractSchemaDetails', actualValue);
                }
              }}
            >
              <SelectTrigger className="w-full bg-white border-gray-300">
                <SelectValue placeholder={`Select ${field.label.toLowerCase()}`} />
              </SelectTrigger>
              <SelectContent className="bg-white">
                {field.options?.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={toStringValue(option.value)}
                    className="py-2 px-4 bg-gray-50 hover:bg-blue-100 cursor-pointer"
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
          {field.type === 'json' && (
            <pre className="w-full p-2 bg-gray-100 rounded overflow-auto">
              {field.dependsOn
                ? JSON.stringify(values[field.dependsOn], null, 2)
                : JSON.stringify(values[field.key] || {}, null, 2)}
            </pre>
          )}
          {field.type === 'file' && (
            <div className="flex items-center space-x-2">
              <Input
                id={field.key}
                type="file"
                onChange={(e) => handleFileChange(field.key, e)}
                className="hidden"
              />
              <Button
                onClick={() => document.getElementById(field.key)?.click()}
                variant="outline"
                className="w-full"
              >
                {values[field.key] ? (values[field.key] as FileTemplate).filename : 'Choose file'}
              </Button>
              {values[field.key] && (
                <Button
                  onClick={() => onChange(field.key, null)}
                  variant="ghost"
                  size="sm"
                >
                  Clear
                </Button>
              )}
            </div>
          )}
          {field.type === 'mapping' && (
            <div className="space-y-2">
              {((values[field.key] as MappingField[]) || []).map((mapping, index) => (
                <div key={index} className="flex space-x-2">
                  <Select
                    value={mapping.input}
                    onValueChange={(value) => handleMappingChange(field.key, index, 'input', value)}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select input" />
                    </SelectTrigger>
                    <SelectContent>
                      {field.options?.map((option) => (
                        <SelectItem key={option.value} value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    value={mapping.data}
                    onChange={(e) => handleMappingChange(field.key, index, 'data', e.target.value)}
                    placeholder="Data"
                  />
                  <Button onClick={() => removeMapping(field.key, index)} variant="destructive" size="sm">
                    Remove
                  </Button>
                </div>
              ))}
              <Button onClick={() => addMapping(field.key)} variant="outline" size="sm">
                Add
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DynamicNodeFields;
