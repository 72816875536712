import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import './styles/globals.css';

import { Home } from './pages/Home';
import { NoMatch } from './pages/NoMatch';
import { Playground } from './components/playground/Playground';
import { PastRuns } from './components/PastRuns';
import { Voice } from './components/voice/Voice';
import { VoiceDetail } from './components/voice/VoiceDetail';
import { Compare } from './components/compare/Compare';
import { CompareDetail } from './components/compare/CompareDetail';
import { FillExcel } from './components/fillexcel/FillExcel';
import { FillExcelDetail } from './components/fillexcel/FillExcelDetail';
import { TemplateBuild } from './components/compare/TemplateBuild';
import { WorkflowBuilder } from './components/WorkflowBuilder';
import { Integrations } from './components/integrations/Integrations';
import { Submissions } from './components/submissions/Submissions';
import { SubmissionDetail } from './components/submissions/SubmissionDetail';
import { UwAudit } from './components/uwaudit/UwAudit';
import { UwAuditDetail } from './components/uwaudit/UwAuditDetail';
import { Toaster } from 'react-hot-toast';
import { enableMapSet } from "immer"

enableMapSet();

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/playground" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/workflow/:workflowId" element={<WorkflowBuilder />} />
        <Route path="/runs" element={<PastRuns />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="/voice" element={<Voice />} />
        <Route path="/voice/logs/:id" element={<VoiceDetail />} />
        <Route path="/compare" element={<Compare />} />
        <Route path="/compare/logs/:id" element={<CompareDetail />} />
        <Route path="/compare/create-template" element={<TemplateBuild />} />
        <Route path="/excel" element={<FillExcel />} />
        <Route path="/excel/logs/:id" element={<FillExcelDetail />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/submissions" element={<Submissions />} />
        <Route path="/submissions/:id" element={<SubmissionDetail />} />
        <Route path="/uw-audit" element={<UwAudit />} />
        <Route path="/audit/logs/:id" element={<UwAuditDetail />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
