import { useState, useRef, useMemo } from "react"
import { Button } from "../ui/button"
import { Sparkle, Search, Filter, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, MoreHorizontal, Plus } from "lucide-react"
import { HomeSidebarV1 } from "../HomeSidebarV1"
import { Input } from "../ui/input"
import { useNodeApi } from '../../hooks/useNodeApi';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import av1 from '../../assets/avatars/av1.svg';
import { useNavigate } from 'react-router-dom'
import { UserDocument, AuditLogResponse } from '../../services/api';
import toast from 'react-hot-toast';
import { UserClass, withRequiredAuthInfo } from "@propelauth/react";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import { UploadDialog } from "../UploadDialog"

type AuditData = {
  id: string;
  personWhoStartedIt: string;
  personImage: string;
  templateName: string;
  numberOfDocuments: number;
  numberOfFieldsMatch: number;
  numberOfFieldsMismatch: number;
  numberOfFieldsNeedsInfo: number;
  date: string;
  time: string;
};

type Schema = {
  name: string;
  value: AuditType;
  isCustom?: boolean;
};

enum AuditType {
  VERIFY_GUIDELINE = "verify_guideline",
  TERRORISM_CHECK = "terrorism_check",
  UNDERWRITER_AUTHORIZATION = "underwriter_authorization",
  CUSTOM = "custom"
}

const auditTypeToDisplayName: Record<string, string> = {
  "verify_guideline": "Check Guideline",
  "terrorism_check": "Check Terrorism",
  "underwriter_authorization": "Check Authorization",
  "custom": "Custom"
};


const schemaTemplates: Schema[] = [
  {
    name: "Check Guideline",
    value: AuditType.VERIFY_GUIDELINE,
  },
  // {
  //   name: "Terrorism Check",
  //   value: AuditType.TERRORISM_CHECK,
  // },
  {
    name: "Check Authorization",
    value: AuditType.UNDERWRITER_AUTHORIZATION,
  },
  {
    name: "Create your own",
    value: AuditType.CUSTOM,
    isCustom: true
  },
];

const pdfFileTypes = {
  mimeTypes: ['application/pdf'],
  errorMessage: 'Please upload PDF files only.'
};

export const UwAudit = withRequiredAuthInfo(({ accessToken, userClass }: { accessToken: string | null, userClass: UserClass | null }) => {
  const navigate = useNavigate()
  const topComponentRef = useRef<HTMLDivElement>(null)
  const { uploadDocument, runAudit, getAuditLogsHistory, deleteDocument } = useNodeApi(accessToken);
  const [auditLogs, setAuditLogs] = useState<AuditLogResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: keyof AuditData; direction: 'asc' | 'desc' | null }>({ key: 'id', direction: null });

  const lastPollTimeRef = useRef(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedSchema, setSelectedSchema] = useState<Schema | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const handleSchemaClick = (template: Schema) => {
    if (template.isCustom) {
      // TODO: Implement custom template creation
      const org = userClass?.getOrgs()[0];
      const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
      if (!isFurtherAI) {
        toast.error("Contact your admin to enable this feature.");
      }
    } else {
      setSelectedSchema(template);
      setIsUploadDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsUploadDialogOpen(false);
    setSelectedSchema(null);
  };

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof AuditData) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  usePolling(
    () => {
      setIsHistoryLoading(true);
      return getAuditLogsHistory();
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      onSuccess: (logs) => {
        setAuditLogs(logs);
        setIsHistoryLoading(false);
      },
      onError: (error) => {
        console.error("Failed to fetch audit logs:", error);
        setIsHistoryLoading(false);
      }
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const formatDate = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleDateString();
  };

  const formatTime = (created_at: string): string => {
    const startDate = new Date(created_at + "Z");
    return startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const sortedAndFilteredData = useMemo(() => {
    return auditLogs
      .map((log) => ({
        id: log._id,
        personWhoStartedIt: "User",
        personImage: av1,
        templateName: log.request.audit_type,
        numberOfDocuments: 1,
        numberOfFieldsMatch: log.result?.rows.filter(row => row.status.toLowerCase() === "match").length || 0,
        numberOfFieldsMismatch: log.result?.rows.filter(row => row.status.toLowerCase() === "mismatch").length || 0,
        numberOfFieldsNeedsInfo: log.result?.rows.filter(row => row.status.toLowerCase() === "needs info").length || 0,
        date: formatDate(log.created_at),
        time: formatTime(log.created_at)
      }))
      .filter((item) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          item.templateName.toLowerCase().includes(searchLower) ||
          item.date.toLowerCase().includes(searchLower) ||
          item.time.toLowerCase().includes(searchLower) ||
          item.numberOfDocuments.toString().includes(searchQuery) ||
          item.numberOfFieldsMatch.toString().includes(searchQuery) ||
          item.numberOfFieldsMismatch.toString().includes(searchQuery) ||
          item.numberOfFieldsNeedsInfo.toString().includes(searchQuery)
        );
      })
      .sort((a, b) => {
        if (sortConfig.direction === null) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [auditLogs, searchQuery, sortConfig]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const columnWidths = {
    checkbox: "w-[60px]",
    template: "w-[200px]",
    documentCount: "w-[150px]",
    fieldsMatched: "w-[100px]",
    fieldsMismatched: "w-[100px]",
    fieldsNeedsInfo: "w-[100px]",
    date: "w-[120px]",
    time: "w-[120px]",
    actions: "w-[60px]"
  } as const;

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof AuditData,
    className?: string
  }) => (
    <TableHead className={`border-y border-gray-200 ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isHistoryLoading}
      >
        <span>{children}</span>
        {sortConfig.key === sortKey && (
          <span className="ml-2">
            {sortConfig.direction === 'asc' ?
              <ChevronUp className="h-4 w-4" /> :
              <ChevronDown className="h-4 w-4" />
            }
          </span>
        )}
      </Button>
    </TableHead>
  );

  const handleRowClick = (audit: AuditData) => {
    navigate(`/audit/logs/${audit.id}`)
  }

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className="border-y border-l border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-r border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  const handleUploadComplete = async (documents: UserDocument[]) => {
    await new Promise(resolve => setTimeout(resolve, 0));

    if (!selectedSchema) {
      toast.error('Please select an audit type before running the audit.');
      return;
    }

    try {
      const documentIds = documents.map(doc => doc._id);
      const result = await runAudit(documentIds, selectedSchema.value);
      navigate(`/audit/logs/${result.audit_log_id}`);
    } catch (error) {
      console.error('Failed to initiate audit:', error);
      toast.error('Failed to initiate audit. Please try again.');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 flex flex-col overflow-hidden ml-56">
        <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
          <div className="max-w-6xl mx-auto px-2">
            <div ref={topComponentRef}>
              <h1 className="text-xl font-semibold mb-6">Underwriting Audit</h1>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4 mb-8">
                {schemaTemplates.map((template) => (
                  <Button
                    key={template.value}
                    variant="outline"
                    size="lg"
                    className={`
                      h-28
                      flex flex-col items-center justify-center
                      hover:bg-gray-50
                      transition-colors
                      bg-white
                      shadow-sm
                      rounded-lg
                      border border-gray-200
                      ${selectedSchema?.value === template.value
                        ? 'ring-2 ring-blue-600 border-transparent'
                        : 'hover:border-gray-300'
                      }
                    `}
                    onClick={() => handleSchemaClick(template)}
                  >
                    {template.isCustom ? (
                      <Plus className="h-6 w-6 text-gray-600 mb-2" />
                    ) : (
                      <Sparkle className="h-6 w-6 text-gray-600 mb-2" />
                    )}
                    <span className="text-sm font-normal text-gray-900">
                      {template.name}
                    </span>
                  </Button>
                ))}
              </div>

              <div className="mt-4">
                <h1 className="text-xl font-semibold mb-4">History</h1>
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                  <div className="p-4">
                    <div className="flex items-center mb-4">
                      <div className="relative mr-2">
                        <Input
                          type="text"
                          placeholder="Search"
                          className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                      </div>
                      <Button variant="outline" size="sm" className="h-9 flex items-center text-gray-400 border-gray-300">
                        <Filter className="h-4 w-4 mr-2" />
                        Filter
                      </Button>
                    </div>

                    <div className="overflow-x-auto">
                      <Table className="border-collapse border border-gray-200 table-fixed w-full">
                        <TableHeader>
                          <TableRow>
                            <TableHead className={`border-y border-l border-gray-200 ${columnWidths.checkbox}`}>
                              {isHistoryLoading ? (
                                <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                              ) : (
                                <Checkbox
                                  checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                                  onCheckedChange={handleSelectAllRows}
                                />
                              )}
                            </TableHead>
                            <SortableTableHead sortKey="templateName" className={columnWidths.template}>
                              Template
                            </SortableTableHead>
                            <SortableTableHead sortKey="numberOfDocuments" className={columnWidths.documentCount}>
                              Document Count
                            </SortableTableHead>
                            <SortableTableHead sortKey="numberOfFieldsMatch" className={columnWidths.fieldsMatched}>
                              Matched
                            </SortableTableHead>
                            <SortableTableHead sortKey="numberOfFieldsMismatch" className={columnWidths.fieldsMismatched}>
                              Mismatched
                            </SortableTableHead>
                            <SortableTableHead sortKey="numberOfFieldsNeedsInfo" className={columnWidths.fieldsNeedsInfo}>
                              Needs Info
                            </SortableTableHead>
                            <SortableTableHead sortKey="date" className={columnWidths.date}>
                              Date
                            </SortableTableHead>
                            <SortableTableHead sortKey="time" className={columnWidths.time}>
                              Time
                            </SortableTableHead>
                            <TableHead className={`border-y border-r border-gray-200 ${columnWidths.actions}`}></TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {isHistoryLoading ? (
                            renderShimmerRows()
                          ) : (
                            paginatedData.map((row) => (
                              <TableRow
                                key={row.id}
                                onClick={() => handleRowClick(row)}
                                className="cursor-pointer hover:bg-gray-50"
                              >
                                <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
                                  <Checkbox
                                    checked={selectedRows.includes(row.id)}
                                    onCheckedChange={(checked) => handleRowSelection(row.id, checked as boolean)}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </TableCell>
                                <TableCell className={`px-4 ${columnWidths.template}`}>
                                  <div className="flex items-center">
                                    <span className="truncate">{auditTypeToDisplayName[row.templateName] || row.templateName}</span>
                                  </div>
                                </TableCell>
                                <TableCell className={`px-4 ${columnWidths.documentCount}`}>{row.numberOfDocuments}</TableCell>
                                <TableCell className={`px-4 ${columnWidths.fieldsMatched}`}>{row.numberOfFieldsMatch}</TableCell>
                                <TableCell className={`px-4 ${columnWidths.fieldsMismatched}`}>{row.numberOfFieldsMismatch}</TableCell>
                                <TableCell className={`px-4 ${columnWidths.fieldsNeedsInfo}`}>{row.numberOfFieldsNeedsInfo}</TableCell>
                                <TableCell className={`px-4 ${columnWidths.date}`}>{row.date}</TableCell>
                                <TableCell className={`px-4 ${columnWidths.time}`}>{row.time}</TableCell>
                                <TableCell className={`px-4 ${columnWidths.actions}`}>
                                  <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                                    <MoreHorizontal className="h-4 w-4" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </div>

                    <div className="flex justify-between items-center mt-4">
                      <p className="text-sm text-gray-500">
                        {isHistoryLoading
                          ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                          : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                        }
                      </p>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500">Rows per page</span>
                        <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                          <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                            <SelectValue placeholder={rowsPerPage.toString()} />
                          </SelectTrigger>
                          <SelectContent className="bg-white">
                            <SelectItem value="5">5</SelectItem>
                            <SelectItem value="10">10</SelectItem>
                            <SelectItem value="20">20</SelectItem>
                          </SelectContent>
                        </Select>
                        <span className="text-sm text-gray-500">
                          Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                        </span>
                        <div className="flex space-x-1">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                          >
                            <ChevronLeft className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                            disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                          >
                            <ChevronRight className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UploadDialog
        isOpen={isUploadDialogOpen}
        onClose={handleDialogClose}
        onUploadComplete={handleUploadComplete}
        uploadDocument={uploadDocument}
        deleteDocument={deleteDocument}
        selectedTemplate={selectedSchema?.value || ''}
        acceptedFileTypes={pdfFileTypes}
        category="compare"
      />
    </div>
  )
})
