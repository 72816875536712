import React, { useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { ScrollArea } from "../components/ui/scroll-area";
import { Button } from "../components/ui/button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type FileType = "excel" | "pdf" | "png" | null;

type ExcelData = (string | number | boolean | null)[][];

interface FileViewerProps {
  fileData: ExcelData | string | ArrayBuffer | Uint8Array | File | null;
  fileType: FileType;
  fileName: string;
}

export function FileViewer({ fileData, fileType, fileName }: FileViewerProps) {
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const pdfFile = useMemo(() => {
    if (fileType === "pdf") {
      if (fileData instanceof File) {
        return fileData;
      } else if (fileData instanceof Uint8Array) {
        return new Blob([fileData], { type: "application/pdf" });
      } else if (typeof fileData === "string") {
        // Assuming the string is a data URL
        return dataURLtoBlob(fileData);
      }
    }
    return undefined;
  }, [fileData, fileType]);

  const imageSource = useMemo(() => {
    if (fileType === "png") {
      if (typeof fileData === "string") {
        return fileData; // Assume it's already a valid URL or data URL
      } else if (fileData instanceof File) {
        return URL.createObjectURL(fileData);
      } else if (fileData instanceof Uint8Array) {
        const blob = new Blob([fileData], { type: "image/png" });
        return URL.createObjectURL(blob);
      }
    }
    return "";
  }, [fileData, fileType]);

  const renderFilePreview = () => {
    switch (fileType) {
      case "excel":
        if (Array.isArray(fileData) && fileData.length > 0 && Array.isArray(fileData[0])) {
          return (
            <Table>
              <TableHeader>
                <TableRow>
                  {(fileData[0] as string[]).map((header, index) => (
                    <TableHead key={index}>{header}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {(fileData as ExcelData).slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell?.toString() ?? ''}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        } else {
          return <p>Unable to parse Excel data. Please check the file format.</p>;
        }
      case "pdf":
        return (
          <div className="flex flex-col items-center">
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              options={{
                cMapUrl: 'https://unpkg.com/pdfjs-dist@2.9.359/cmaps/',
                cMapPacked: true,
              }}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <div className="flex gap-2 mt-2">
              <Button
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber <= 1}
              >
                Previous
              </Button>
              <Button
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber >= (numPages || 0)}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case "png":
        return (
          <img
            src={imageSource}
            alt={`Uploaded PNG: ${fileName}`}
            className="max-h-full max-w-full object-contain"
          />
        );
      default:
        return (
          <div className="flex h-full items-center justify-center">
            <p className="text-lg text-muted-foreground">
              Unable to preview this file type.
            </p>
          </div>
        );
    }
  };

  return (
    <ScrollArea className="h-full w-full">
      {renderFilePreview()}
    </ScrollArea>
  );
}

// Helper function to convert data URL to Blob
function dataURLtoBlob(dataurl: string): Blob {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
