import { Button } from "../components/ui/button";
import { Separator } from "../components/ui/separator";
import { Plus, Home, Clock, MoreHorizontal } from "lucide-react";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNodeApi } from '../hooks/useNodeApi';
import av1 from '../assets/avatars/av1.svg';
import av3 from '../assets/avatars/av3.svg';
import furtherAiLogo from '../assets/fai-logo.svg';
import { withAuthInfo } from "@propelauth/react";

export const HomeSidebar = withAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const [activeButton, setActiveButton] = useState('');
  const navigate = useNavigate();
  const { createWorkflow } = useNodeApi(accessToken);

  const handleCreateClick = async () => {
    try {
      const newWorkflow = await createWorkflow({
        name: 'New Workflow 1',
        description: '...',
        nodes: [],
        edges: []
      });
      navigate(`/workflow/${newWorkflow.workflow_id}`);
    } catch (error) {
      console.error('Failed to create workflow', error);
    }
  };

  return (
    <div className="flex flex-col h-screen w-64 bg-white border-r border-gray-200">
      <div className="p-4 mx-2 my-2">
        <img
          src={furtherAiLogo}
          alt="Further AI"
          className="h-6"
          width={160}
          height={34}
        />
      </div>
      <div className="px-3 py-4">
        <Button
          className="w-full bg-blue-600 hover:bg-blue-800 text-white"
          onClick={handleCreateClick}
        >
          <Plus className="mr-2 h-4 w-4" /> Create
        </Button>
      </div>
      <nav className="flex-1 overflow-y-auto">
        <ul className="space-y-1 px-2">
          <li>
            <Button
              variant="ghost"
              className="w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700"
              onClick={() => {
                setActiveButton('home');
                navigate('/home');
              }}
            >
              <Home
                className={`mr-2 h-4 w-4 ${activeButton === 'home' ? 'fill-blue-600 text-gray-700' : ''}`}
                strokeWidth={1.5}
              />
              Home
            </Button>
          </li>
          <Separator className="my-2 border-t border-gray-300 mx-2 w-11/12" />
          <li>
            <Button
              variant="ghost"
              className={`w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700`}
              onClick={() => setActiveButton('smith')}
            >
              <div className={`flex items-center justify-center rounded-full ${activeButton === 'smith' ? 'bg-blue-600' : 'bg-gray-200'} -mx-2 h-8 w-8 mr-2`}>
                <img src={av1} alt="Avatar 1" className="h-6 w-6" />
              </div>
              Smith
            </Button>
          </li>
          <li>
            <Button
              variant="ghost"
              className={`w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700`}
              onClick={() => setActiveButton('kate')}
            >
              <div className={`flex items-center justify-center rounded-full ${activeButton === 'kate' ? 'bg-blue-600' : 'bg-gray-200'} -mx-2 h-8 w-8 mr-2`}>
                <img src={av3} alt="Avatar 3" className="h-6 w-6" />
              </div>
              Kate
            </Button>
          </li>
          <Separator className="my-2 border-t border-gray-300 mx-2 w-11/12" />
          <li>
            <Button
              variant="ghost"
              className="w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700"
              onClick={() => {
                setActiveButton('pastRuns');
                navigate('/runs');
              }}
            >
              <Clock
                className={`mr-2 h-4 w-4 ${activeButton === 'pastRuns' ? 'fill-blue-600 text-gray-700' : ''}`}
                strokeWidth={1.5}
              />
              Past Runs
            </Button>
          </li>
          <li>
            <Button variant="ghost" className="w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700">
              <MoreHorizontal className="mr-2 h-4 w-4" /> More
            </Button>
          </li>
        </ul>
      </nav>
      <div className="mt-auto">
        <Separator />
        <div className="p-4">
          <div className="flex items-center space-x-2">
            <div className="relative">
              <img
                src="https://res.cloudinary.com/subframe/image/upload/v1725373200/uploads/299/p7tnzanxwdjlibw4tdxj.png"
                alt="User Avatar"
                className="rounded-full"
                width={32}
                height={32}
              />
              <div className="absolute -right-1 -bottom-1 w-4 h-4 bg-gray-300 rounded-full flex items-center justify-center text-gray-600 text-[8px] font-medium border-2 border-white">
                JD
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pb-4 text-xs text-gray-500">
          <div className="flex justify-between items-center mb-1">
            <span>Included Tasks</span>
            <span>347 / 2,000</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-1 mb-1">
            <div className="bg-blue-600 h-1 rounded-full" style={{ width: '17.35%' }}></div>
          </div>
          <div className="flex justify-between items-center text-[10px]">
            <span>Usage resets in 4 weeks</span>
            <a href="#" className="text-blue-500 hover:underline">Manage plan</a>
          </div>
        </div>
      </div>
    </div>
  );
})
