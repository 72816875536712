import { useState, useEffect, useRef, useCallback } from "react"
import { Button } from "../ui/button"
import { Progress } from "../ui/progress"
import { X, Upload, FileText, Play, Sparkle, Save, Search, Star, ArrowUp, Copy } from "lucide-react"
import { AutoResizeTextarea } from "../AutoResizeTextArea"
import { HomeSidebarV1 } from "../HomeSidebarV1"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { useNodeApi } from '../../hooks/useNodeApi';
import { GetUserSchemasResponse, UserDocument, UserDocumentUploadRequest } from '../../services/api';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import DocumentPreviewDialog from '../DocumentPreviewDialog';
import { UserClass, withRequiredAuthInfo } from "@propelauth/react";

export const Playground = withRequiredAuthInfo(({ accessToken, userClass }: { accessToken: string | null, userClass: UserClass | null }) => {
  const [files, setFiles] = useState<string[]>([])
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>([]);
  const [isStreaming, setIsStreaming] = useState(false)
  const [streamedResponse, setStreamedResponse] = useState<string>("")
  const [showCompressedTop, setShowCompressedTop] = useState(false)
  const topComponentRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const isFileUploaded = files.length > 0
  const [previewFileType, setPreviewFileType] = useState<'pdf' | 'image'>('pdf');

  const org = userClass?.getOrgs()[0];
  const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];

  const templates = [
    {
      name: "Summarizing Policy",
      isFavorite: true,
      question: `Please provide a concise summary of the attached insurance policy document. Your summary should highlight:
• main coverage areas
• policy limits
• Exclusions
• Premiums
• and any important terms or conditions outlined in the policy.

Ensure that the summary is clear and easy to understand for someone who has not read the original document.`
    },
    {
      name: "Calculate Total Losses",
      isFavorite: false,
      question: `Please analyze the attached loss documents and calculate the total losses incurred.

Provide a detailed breakdown of each loss, including relevant information such as dates, loss types, descriptions, and associated costs. Summarize your findings by presenting the overall total loss amount. Ensure that your calculation is accurate and that the summary is clear and easy to understand for someone reviewing the losses for the first time.`
    },
    {
      name: "Risk Recommendation",
      isFavorite: false,
      question: `Please analyze the company's policy document to determine its risk levels.

Based on this analysis, generate a list of potential insurance carriers that would be willing to underwrite policies aligning with this risk profile. For each carrier, provide a brief explanation of why they are a suitable match, considering factors such as their underwriting guidelines, coverage specialties, and historical preferences for similar risks.Ensure that your summary is clear and informative for someone evaluating underwriting options for the first time.`
    },
    {
      name: "Coverage Gap Analysis",
      isFavorite: false,
      question: `Please review the client's current insurance coverage as detailed in the provided documents. Conduct a Coverage Gap Identification by analyzing the existing policies to pinpoint areas where additional coverage may be necessary to protect against specific risks. For each potential gap, provide:
• A clear description of the uncovered or underinsured risk.
• An explanation of why the current coverage is insufficient.
• Recommendations for additional coverage options to address the gap.

Ensure that your analysis is comprehensive and presented in a manner that is easy for the client to understand, even if they are unfamiliar with insurance terminology.`
    },
    {
      name: "Compliance Check",
      isFavorite: false,
      question: `Please review the provided insurance policy documents and perform a Policy Compliance Check. Your task is to ensure that each policy:
• Meets all relevant regulatory requirements applicable in the jurisdiction(s) where the policy is issued.
• Adheres to the company's internal underwriting guidelines.

For each policy, please provide:
• A detailed analysis identifying any areas of non-compliance with regulations or internal guidelines.
• Specific references to the regulatory statutes or internal policies that apply.
• Recommendations for adjustments needed to achieve full compliance.

Ensure that your report is thorough and presented in clear language that can be easily understood by stakeholders unfamiliar with regulatory or underwriting complexities`
    },
    {
      name: "Get Data From AMS",
      isFavorite: false,
      question: `Please access the Agency Management System (AMS) to retrieve data on the next 10 upcoming policy renewals. For each renewal, provide a concise summary that includes:
• Client Name and contact information
• Policy Number and Policy Type
• Current Coverage Details and Premium Amounts
• Renewal Date and any relevant deadlines
• Any Requested Changes or Endorsements
• Outstanding Issues or requirements (e.g., missing documents, pending payments)

Organize the information in a clear and easy-to-read format, such as a table or bullet points, to facilitate quick review. Ensure that all sensitive client information is handled securely and in compliance with data protection regulations.`
    },
    {
      name: "Policy Comparison",
      isFavorite: false,
      question: `Compare these two insurance policy documents side-by-side, focusing on key areas such as coverage limits, exclusions, endorsements, premium costs, renewal terms, and cancellation clauses.

Highlight any notable differences in coverage, restrictions, or benefits, and explain how these differences might impact the policyholder's risk or coverage options.

Additionally, identify any unique terms or features in each policy that may provide an advantage or disadvantage to the insured.`
    }
  ].concat(isFurtherAI ? [{
    name: "Verify Data From Salesforce",
    isFavorite: false,
    question: `Please verify if the information on this policy document matches information in Salesforce.

Organize the information in a clear and easy-to-read format, such as a table or bullet points, to facilitate quick review. Ensure that all sensitive client information is handled securely and in compliance with data protection regulations.`
  }] : []);

  const [promptText, setPromptText] = useState(templates.find(template => template.isFavorite)?.question || "")
  const [searchQuery, setSearchQuery] = useState("")
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState("")
  const { uploadDocument, deleteDocument, getAssistantResponseSSE, cancelStreaming, isLoading, error, createTemplate, fetchUserSchemas } = useNodeApi(accessToken);

  const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [savedTemplates, setSavedTemplates] = useState<GetUserSchemasResponse[]>([]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files
    if (newFiles) {
      const validFiles = Array.from(newFiles).filter(file => {
        const fileType = file.type.toLowerCase();
        return fileType.startsWith('image/') || fileType === 'application/pdf';
      });

      for (const file of validFiles) {
        try {
          const uploadRequest: UserDocumentUploadRequest = {
            filename: file.name,
            blob_url: "",
            origin: "upload",
            category: "playground",
            owner_uid: "",
            owner_oid: "",
            run_id: "",
          };

          const { user_document_id, sas_url } = await uploadDocument(file, uploadRequest);
          setUploadedDocuments(prev => [...prev, { _id: user_document_id, filename: file.name, blob_url: sas_url } as UserDocument]);
          setFiles(prevFiles => [...prevFiles, file.name]);
        } catch (error) {
          console.error(`Failed to upload file ${file.name}:`, error);
          toast.error(`Failed to upload file ${file.name}`);
        }
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }

  const removeFile = async (fileName: string) => {
    const documentToRemove = uploadedDocuments.find(doc => doc.filename === fileName);
    if (documentToRemove) {
      try {
        await deleteDocument(documentToRemove._id);
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileName));
        setUploadedDocuments((prevDocs) => prevDocs.filter((doc) => doc._id !== documentToRemove._id));

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error(`Failed to delete document ${fileName}:`, error);
        toast.error(`Failed to delete document ${fileName}`);
      }
    }
  }

  const [progress, setProgress] = useState(0)
  const totalExpectedLength = useRef(0)

  const handleRun = useCallback(() => {
    setIsStreaming(true);
    setStreamedResponse("");
    setProgress(0);
    totalExpectedLength.current = 0;

    getAssistantResponseSSE(
      {
        user_prompt: promptText,
        user_documents: uploadedDocuments.map(doc => doc._id),
      },
      (deltaContent) => {
        setStreamedResponse((prev) => {
          const newResponse = prev + deltaContent;
          if (totalExpectedLength.current === 0) {
            totalExpectedLength.current = newResponse.length * 1.1;
          }
          const newProgress = Math.min((newResponse.length / totalExpectedLength.current) * 100, 99);
          setProgress(newProgress);
          return newResponse;
        });
      },
      (finalContent) => {
        setStreamedResponse(finalContent);
        setIsStreaming(false);
        setProgress(100);
      },
      (errorMessage) => {
        console.error('Error in component:', errorMessage);
        setStreamedResponse(errorMessage);
        setIsStreaming(false);
        setProgress(0);
      }
    );
  }, [promptText, uploadedDocuments, getAssistantResponseSSE]);

  const handleCancelStreaming = () => {
    cancelStreaming();
    setIsStreaming(false);
    setStreamedResponse("");
    setProgress(0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (topComponentRef.current) {
        const { top } = topComponentRef.current.getBoundingClientRect()
        setShowCompressedTop(top < 0)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        handleRun();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleRun]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleDocumentClick = (document: UserDocument) => {
    const fileExtension = document.filename.split('.').pop()?.toLowerCase();
    if (fileExtension === 'pdf') {
      setPreviewFileType('pdf');
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension || '')) {
      setPreviewFileType('image');
    } else {
      return;
    }
    setPreviewUrl(document.blob_url);
    setIsPreviewOpen(true);
  };

  const handleSaveTemplate = async () => {
    try {
      if (!promptText.trim()) {
        throw new Error("prompt_empty");
      }
      if (!newTemplateName.trim()) {
        throw new Error("name_empty");
      }

      await createTemplate(
        newTemplateName,
        [
          {
            name: "prompt",
            type: "string",
            description: promptText
          }
        ],
        "playground"
      );

      const schemas = await fetchUserSchemas("playground");
      setSavedTemplates(schemas);

      toast.success("Template saved successfully");
      setNewTemplateName("");
      setIsSaveDropdownOpen(false);
    } catch (err) {
      console.error("Failed to save template:", err);
      let errorMessage = "Failed to save template. Please try again.";
      if (err instanceof Error) {
        switch (err.message) {
          case "prompt_empty":
            errorMessage = "Please enter a prompt before saving.";
            break;
          case "name_empty":
            errorMessage = "Please enter a name for the template.";
            break;
        }
      }
      toast.error(errorMessage);
    }
  };

  const hasLoadedTemplates = useRef(false);

  useEffect(() => {
    const loadSavedTemplates = async () => {
      if (hasLoadedTemplates.current) return;
      hasLoadedTemplates.current = true;

      try {
        const schemas = await fetchUserSchemas(
          "playground"
        );
        setSavedTemplates(schemas);
      } catch (error) {
        console.error("Failed to load templates:", error);
        hasLoadedTemplates.current = false;
      }
    };

    loadSavedTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allTemplates = [
    ...templates,
    ...savedTemplates.map(schema => {
      const parsedSchema = JSON.parse(schema.json_schema);
      return {
        name: schema.name,
        question: parsedSchema.properties.prompt?.description || "",
        isFavorite: false
      };
    })
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 flex flex-col overflow-hidden ml-56">
        <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
          <div className="max-w-6xl mx-auto px-2">
            <div ref={topComponentRef}>
              <h1 className="text-xl font-semibold mb-4">Q&A</h1>
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                  <div className="flex flex-wrap gap-4 mb-4">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="focus-visible:ring-0 focus:ring-0 focus:ring-offset-0"
                        >
                          <Sparkle className="h-4 w-4 mr-2" />
                          Load template
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className="w-64 bg-white"
                        align="start"
                      >
                        <div className="p-2">
                          <div className="relative">
                            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                            <Input
                              type="text"
                              placeholder="Search for a prompt"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              className="pl-8 pr-2 py-1 w-full text-sm focus:ring-0"
                            />
                          </div>
                        </div>
                        {allTemplates
                          .filter(template => template.name.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((template) => (
                            <DropdownMenuItem
                              key={template.name}
                              onSelect={() => setPromptText(template.question)}
                              className="flex items-center justify-between"
                            >
                              <span>{template.name}</span>
                              {template.isFavorite && (
                                <Star className="h-4 w-4 text-gray-800" />
                              )}
                            </DropdownMenuItem>
                          ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <DropdownMenu open={isSaveDropdownOpen} onOpenChange={setIsSaveDropdownOpen}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="focus-visible:ring-0 focus:ring-0 focus:ring-offset-0"
                          onClick={(e) => {
                            const org = userClass?.getOrgs()[0];
                            const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
                            if (!isFurtherAI) {
                              e.preventDefault();
                              toast.error("Contact your admin to enable this feature.");
                            }
                          }}
                        >
                          <Save className="h-4 w-4 mr-2" />
                          Save template
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-[400px] p-4 bg-white" align="start">
                        <div className="space-y-4">
                          <p className="text-sm text-gray-600">
                            This will save the current <span className="font-medium text-gray-900">template</span> state as a preset which you can access later or share with others.
                          </p>

                          <div className="space-y-2">
                            <Label className="text-sm">Name</Label>
                            <div className="flex gap-2">
                              <Input
                                value={newTemplateName}
                                onChange={(e) => setNewTemplateName(e.target.value)}
                                placeholder="Enter template name"
                                className="flex-1"
                              />
                              <Button
                                onClick={handleSaveTemplate}
                                className="bg-black text-white hover:bg-gray-800 px-4"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <AutoResizeTextarea
                    value={promptText}
                    onChange={(e) => setPromptText(e.target.value)}
                    placeholder="Enter your prompt here"
                    className="mb-4 w-full"
                  />
                  <div className="border-t border-gray-200 -mx-4 -mb-4 px-4 py-4 bg-gray-50">
                    <div className="flex flex-wrap items-center justify-between gap-4">
                      <div className="flex flex-wrap items-center gap-2">
                        <input
                          type="file"
                          multiple
                          onChange={handleFileUpload}
                          className="hidden"
                          id="file-upload"
                          ref={fileInputRef}
                          accept="image/*,.pdf"
                        />
                        <Button
                          variant="outline"
                          size="sm"
                          asChild
                          className={`
                          text-gray-900 hover:text-gray-900 border hover:bg-white
                          ${isFileUploaded
                              ? 'border-[#FFEFEF]'
                              : 'border-gray-300'
                            }
                        `}
                        >
                          <label htmlFor="file-upload" className="cursor-pointer">
                            <Upload className="h-4 w-4 mr-2" />
                            Upload
                          </label>
                        </Button>
                        <div className="flex flex-wrap gap-2 items-center">
                          {uploadedDocuments.map((document) => (
                            <div
                              key={document._id}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDocumentClick(document);
                              }}
                              className="inline-flex items-center text-gray-900 border border-gray-300 rounded-md text-sm bg-white hover:bg-gray-50 transition-colors px-3 py-2 h-9 document-preview-link"
                            >
                              <div className="relative w-6 h-6 mr-2 flex-shrink-0">
                                <div className="absolute inset-0 bg-[#FFEFEF] rounded-sm"></div>
                                <FileText className="absolute inset-0 w-4 h-4 stroke-red-600 m-1" strokeWidth={1.5} />
                              </div>
                              <span className="truncate max-w-[150px]">{document.filename}</span>
                              <button
                                onClick={(e) => { e.stopPropagation(); removeFile(document.filename) }}
                                className="ml-2 text-gray-500 hover:text-gray-900"
                              >
                                <X className="h-4 w-4" />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Button
                        onClick={handleRun}
                        disabled={isLoading || !promptText.trim()}
                        className={`
                        bg-blue-50 border border-blue-100 text-blue-900 rounded-md px-4 py-2 flex items-center
                        ${(isLoading || !promptText.trim()) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-200'}
                      `}
                      >
                        <Play className="h-4 w-4 mr-2 fill-blue-900" />
                        Run
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {(isStreaming || streamedResponse) && (
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                  <div className="p-4">
                    {isStreaming && <Progress value={progress} className="w-full mb-3 h-2" />}
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
                      <span className="text-sm text-gray-900 font-semibold">
                        {isStreaming ? "Processing.." : ""}
                      </span>
                      {isStreaming && (
                        <button
                          onClick={handleCancelStreaming}
                          className="text-sm text-blue-900 hover:underline flex items-center"
                        >
                          <X className="h-4 w-4 mr-1" />
                          Cancel Query
                        </button>
                      )}
                    </div>
                    <div className="prose max-w-none">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          table: (props) => (
                            <table className="border-collapse border border-gray-300 my-4 w-full" {...props} />
                          ),
                          th: (props) => (
                            <th className="border border-gray-300 px-4 py-2 bg-gray-100 font-semibold" {...props} />
                          ),
                          td: (props) => (
                            <td className="border border-gray-300 px-4 py-2" {...props} />
                          ),
                          h1: (props) => <h1 className="text-3xl font-bold mt-6 mb-4" {...props} />,
                          h2: (props) => <h2 className="text-2xl font-semibold mt-5 mb-3" {...props} />,
                          h3: (props) => <h3 className="text-xl font-medium mt-4 mb-2" {...props} />,
                          p: (props) => <p className="my-2" {...props} />,
                          ul: (props) => <ul className="list-disc pl-5 my-3" {...props} />,
                          ol: (props) => <ol className="list-decimal pl-5 my-3" {...props} />,
                          li: (props) => <li className="my-1" {...props} />,
                          blockquote: (props) => (
                            <blockquote className="border-l-4 border-gray-300 pl-4 py-2 my-4 italic" {...props} />
                          ),
                          code: (props) => {
                            const { children, className } = props
                            const match = /language-(\w+)/.exec(className || '')
                            return match ? (
                              <pre className="bg-gray-100 rounded p-2 overflow-x-auto my-4">
                                <code className={className} {...props}>
                                  {children}
                                </code>
                              </pre>
                            ) : (
                              <code className="bg-gray-100 rounded px-1 py-0.5 font-mono text-sm" {...props}>
                                {children}
                              </code>
                            )
                          },
                          pre: (props) => <pre className="bg-gray-100 rounded p-2 overflow-x-auto my-4" {...props} />,
                          a: (props) => <a className="text-blue-600 hover:underline" {...props} />,
                          img: (props) => <img className="max-w-full h-auto my-4" {...props} />,
                        }}
                      >
                        {streamedResponse}
                      </ReactMarkdown>
                      {streamedResponse && (
                        <div className="flex items-center gap-2 mt-2">
                          <button
                            onClick={async () => {
                              try {
                                await navigator.clipboard.writeText(streamedResponse);
                                toast.success('Copied to clipboard');
                              } catch (error) {
                                toast.error('Failed to copy to clipboard');
                              }
                            }}
                            className="inline-flex items-center gap-1.5 p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded transition-colors"
                          >
                            <Copy className="h-4 w-4" />
                            <span className="text-sm">Copy</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {error && <div className="text-red-500 mt-2">{error}</div>}
            </div>
            {showCompressedTop && (
              <div className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 shadow-sm z-10 transition-all duration-300 ease-in-out">
                <div className="max-w-6xl mx-auto flex flex-col sm:flex-row justify-between items-start sm:items-center h-auto sm:h-16 px-4 py-2 sm:py-0">
                  <div className="flex items-center space-x-4 mb-2 sm:mb-0">
                    <span className="font-semibold text-lg text-gray-900">Q&A</span>
                    {isStreaming && (
                      <span className="text-sm text-blue-600 font-medium bg-blue-100 px-3 py-1 rounded-full">
                        Processing...
                      </span>
                    )}
                  </div>
                  <Button
                    onClick={scrollToTop}
                    variant="ghost"
                    size="sm"
                    className="text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                  >
                    <ArrowUp className="h-4 w-4 mr-2" />
                    Back to Top
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DocumentPreviewDialog
        isOpen={isPreviewOpen}
        onClose={() => {
          setPreviewUrl("");
          setIsPreviewOpen(false);
        }}
        previewUrl={previewUrl}
        fileType={previewFileType}
      />
    </div>
  )
})
