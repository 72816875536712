import React from 'react';
import { FileText, Code, ChevronDown, ChevronUp, X } from 'lucide-react';
import { Button } from './ui/button';
import { DroppedNode } from '../types/chat';

interface PreviewNodeProps {
  node: DroppedNode;
  onExpand: () => void;
  onClick: () => void;
  onRemove: () => void;
  onDragStart: (e: React.MouseEvent) => void;
  onStartConnection: (nodeId: string, side: 'top' | 'right' | 'bottom' | 'left', e: React.MouseEvent<HTMLDivElement>) => void;
  onEndConnection: (nodeId: string, side: 'top' | 'right' | 'bottom' | 'left', e: React.MouseEvent<HTMLDivElement>) => void;
}

export const PreviewNode: React.FC<PreviewNodeProps> = ({
  node,
  onExpand,
  onClick,
  onRemove,
  onDragStart,
  onStartConnection,
  onEndConnection
}) => {
  const Icon = node.iconName === 'FileText' ? FileText : Code;

  return (
    <div
      className="absolute flex flex-col items-start rounded-md bg-white border border-gray-200 shadow-sm overflow-visible"
      style={{ left: node.x, top: node.y, width: 200, minHeight: 80 }}
      onClick={onClick}
    >
      <div
        className="flex items-center w-full p-3 bg-gray-50 cursor-move"
        onMouseDown={onDragStart}
      >
        <Icon className="h-4 w-4" />
        <span className="ml-2 font-medium">{node.name}</span>
        <Button
          variant="ghost"
          size="icon"
          className="ml-auto"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      <div className="p-3 w-full">
        {node.isExpanded && node.id === "json-response" && (
          <pre className="text-xs overflow-auto max-h-32 bg-gray-100 p-2 rounded">
            {JSON.stringify(node.data, null, 2)}
          </pre>
        )}
        <Button
          variant="outline"
          size="sm"
          className="w-full mt-2 text-xs"
          onClick={(e) => {
            e.stopPropagation();
            onExpand();
          }}
        >
          {node.isExpanded ? <ChevronUp className="h-3 w-3 mr-1" /> : <ChevronDown className="h-3 w-3 mr-1" />}
          {node.isExpanded ? "Collapse" : "Expand"}
        </Button>
      </div>
      <div
        className="absolute w-3 h-3 border-2 border-gray-400 rounded-full cursor-pointer bg-white right-0 top-1/2 translate-x-1/2 -translate-y-1/2"
        onMouseDown={(e) => onStartConnection(node.id, 'right', e)}
        onMouseUp={(e) => onEndConnection(node.id, 'right', e)}
      />
    </div>
  );
};
