import { Switch } from './ui/switch';
import { Label } from './ui/label';

interface ToggleSwitchProps {
  isChecked: boolean;
  onToggle: () => void;
  label: string;
}

export function ToggleSwitch({ isChecked, onToggle }: ToggleSwitchProps) {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        id="mode-toggle"
        checked={isChecked}
        onCheckedChange={onToggle}
        className="bg-gray-200 data-[state=checked]:bg-primary border border-gray-300 hover:bg-gray-300 transition-colors duration-200"
      />
      <Label htmlFor="mode-toggle" className="transition-opacity duration-200">
        {isChecked ? "Chat Mode" : "Debug Mode"}
      </Label>
    </div>
  );
}
