import React, { useState } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/sheet";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { LayoutGrid, Wrench, History, Search } from "lucide-react";
import { NodeType, FilterType } from './WorkflowBuilder'; // Assuming these types are exported from WorkflowBuilderV0
import { NodeTypeIcon } from './NodeTypeIcon'; // Assuming you've moved NodeTypeIcon to a separate file

interface AddNodeSidebarProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  availableNodeTypes: NodeType[];
  builtInTools: string[];
  history: string[];
  onSelectNodeType: (type: string) => void;
}

const SearchBar = () => (
  <div className="flex items-center space-x-2">
    <div className="relative flex-grow">
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      <Input
        type="text"
        placeholder="Search 7,000+ apps and tools..."
        className="pl-10 pr-4 py-2 w-full bg-gray-100 border-none rounded-md"
      />
    </div>
  </div>
);

const FilterOptions: React.FC<{
  currentFilter: FilterType | null;
  setCurrentFilter: React.Dispatch<React.SetStateAction<FilterType | null>>;
}> = ({ currentFilter, setCurrentFilter }) => (
  <div className="flex space-x-2 mb-4">
    {['nodes', 'built-in-tools', 'history'].map((filter) => (
      <Button
        key={filter}
        variant={currentFilter === filter ? "default" : "outline"}
        size="sm"
        className="flex items-center hover:bg-blue-400 transition-colors"
        onClick={() => setCurrentFilter(prev => prev === filter ? null : filter as FilterType)}
      >
        {filter === 'nodes' && <LayoutGrid className="mr-2 h-4 w-4" />}
        {filter === 'built-in-tools' && <Wrench className="mr-2 h-4 w-4" />}
        {filter === 'history' && <History className="mr-2 h-4 w-4" />}
        {filter.charAt(0).toUpperCase() + filter.slice(1).replace('-', ' ')}
      </Button>
    ))}
  </div>
);

const NodesView: React.FC<{
  nodeTypes: NodeType[];
  onSelectNodeType: (type: string) => void;
  onClose: () => void;
}> = ({ nodeTypes, onSelectNodeType, onClose }) => (
  <div>
    <h3 className="text-sm font-medium text-gray-500 mb-2">All Nodes</h3>
    <div className="grid grid-cols-2 gap-2">
      {nodeTypes.map((type) => (
        <button
          key={type.id}
          onClick={() => {
            onSelectNodeType(type.id);
            onClose();
          }}
          className="w-full text-left focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md hover:bg-blue-100 transition-colors"
        >
          <NodeTypeIcon type={type.id} name={type.name} />
        </button>
      ))}
    </div>
  </div>
);

const BuiltInToolsView: React.FC<{ builtInTools: string[] }> = ({ builtInTools }) => (
  <div>
    <h3 className="text-sm font-medium text-gray-500 mb-2">Built-in Tools</h3>
    <div className="grid grid-cols-2 gap-2">
      {builtInTools.map((tool) => (
        <div key={tool} className="hover:bg-blue-100 transition-colors rounded-md">
          <NodeTypeIcon type={tool} name={`${tool.charAt(0).toUpperCase() + tool.slice(1)}`} />
        </div>
      ))}
    </div>
  </div>
);

const HistoryView: React.FC<{ history: string[] }> = ({ history }) => (
  history.length > 0 ? (
    <div>
      <h3 className="text-sm font-medium text-gray-500 mb-2">History</h3>
      <ul className="space-y-2">
        {history.map((item, index) => (
          <li key={index} className="text-sm">{item}</li>
        ))}
      </ul>
    </div>
  ) : null
);

const AllComponentsView: React.FC<{
  nodeTypes: NodeType[];
  onSelectNodeType: (type: string) => void;
  onClose: () => void;
  builtInTools: string[];
  history: string[];
}> = ({ nodeTypes, onSelectNodeType, onClose, builtInTools, history }) => (
  <div className="space-y-6">
    <NodesView nodeTypes={nodeTypes} onSelectNodeType={onSelectNodeType} onClose={onClose} />
    <BuiltInToolsView builtInTools={builtInTools} />
    <HistoryView history={history} />
  </div>
);

export const AddNodeSidebar: React.FC<AddNodeSidebarProps> = ({
  isOpen,
  onOpenChange,
  availableNodeTypes,
  builtInTools,
  history,
  onSelectNodeType,
}) => {
  const [currentFilter, setCurrentFilter] = useState<FilterType | null>(null);

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent side="right" className="w-[400px] sm:w-[600px]">
        <SheetHeader>
          <SheetTitle className="text-xl font-semibold">Add New Node</SheetTitle>
        </SheetHeader>
        <div className="mt-6 space-y-6">
          <SearchBar />
          <FilterOptions currentFilter={currentFilter} setCurrentFilter={setCurrentFilter} />
          {currentFilter === null && (
            <AllComponentsView
              nodeTypes={availableNodeTypes}
              onSelectNodeType={onSelectNodeType}
              onClose={() => onOpenChange(false)}
              builtInTools={builtInTools}
              history={history}
            />
          )}
          {currentFilter === 'nodes' && (
            <NodesView
              nodeTypes={availableNodeTypes}
              onSelectNodeType={onSelectNodeType}
              onClose={() => onOpenChange(false)}
            />
          )}
          {currentFilter === 'built-in-tools' && <BuiltInToolsView builtInTools={builtInTools} />}
          {currentFilter === 'history' && <HistoryView history={history} />}
        </div>
      </SheetContent>
    </Sheet>
  );
};
