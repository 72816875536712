import { getWorkflowNodeIcon } from '../lib/iconUtils';

export const NodeTypeIcon = ({ type, name }: { type: string; name: string }) => {
  const IconOrSrc = getWorkflowNodeIcon(type);
  return (
    <div className="flex items-center space-x-3 p-2 rounded-md transition-colors w-full">
      <div className="w-8 h-8 flex items-center justify-center rounded-md bg-gray-200">
        {typeof IconOrSrc === 'string' ? (
          <img src={IconOrSrc} alt={name} className="w-5 h-5 object-contain" />
        ) : (
          <IconOrSrc className="w-5 h-5" />
        )}
      </div>
      <span className="text-sm">{name}</span>
    </div>
  );
};
