import React, { useState, useEffect, useRef } from 'react';
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { SendIcon, ChevronRightIcon, PaperclipIcon } from 'lucide-react';
import { ActiveChat } from "../components/ActiveChat";
import { HomeSidebar } from "../components/HomeSidebar";
import { WorkflowBuilder } from "../components/WorkflowBuilder";
import { PastRuns } from "../components/PastRuns";

export function Home() {
  const [showChat, setShowChat] = useState(false);
  const [isDebugMode, setIsDebugMode] = useState(false);
  const [initialMessage, setInitialMessage] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [activeView] = useState('home');

  const toggleChatbar = () => {
    setShowChatbar(prev => !prev);
    if (!showChatbar) {
      setInputValue('');
    }
  };

  const handlePromptClick = (prompt: string) => {
    setInitialMessage(prompt);
    setShowChat(true);
    setShowChatbar(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (inputValue.trim()) {
      handlePromptClick(inputValue);
      setInputValue('');
    }
  };

  const [showChatbar, setShowChatbar] = useState(false);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  const handleToggleDebugMode = () => {
    setIsDebugMode(isDebugMode);
  };

  return (
    <div className="flex h-screen bg-background text-foreground font-sans">
      <HomeSidebar/>
      <div className="flex-grow flex flex-col h-full relative">
        {activeView === 'pastRuns' ? (
          <PastRuns />
        ) : showChat ? (
          isDebugMode ? (
            <WorkflowBuilder />
          ) : (
            <ActiveChat
              initialMessage={initialMessage}
              onToggleDebugMode={handleToggleDebugMode}
            />
          )
        ) : (
          <div className="flex flex-col items-center justify-center h-full p-8">
            <h1 className="text-4xl font-bold text-center mb-3 max-w-2xl">
              Automate your workflow in a flash.
            </h1>
            <p className="text-lg text-center text-gray-600 mb-12 max-w-2xl">
              Generate Workflows, ask questions, debug, execute flows, and much more.
            </p>

            {showChatbar && (
              <div className="w-full max-w-3xl mb-8">
                <div className="relative">
                  <Textarea
                    ref={textareaRef}
                    placeholder="Write me an Auto Workflow..."
                    className="w-full pr-24 py-6 text-base rounded-3xl placeholder-gray-400 border-2 border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 resize-none overflow-hidden min-h-[60px]"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    rows={1}
                  />
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
                    <button className="p-2 hover:font-bold transition-all duration-200">
                      <PaperclipIcon className="h-5 w-5 text-gray-400" />
                    </button>
                    <button
                      className={`p-2 transition-all duration-200 ${inputValue.trim() ? 'hover:font-bold' : ''}`}
                      onClick={handleSend}
                    >
                      <SendIcon className={`h-5 w-5 ${inputValue.trim() ? 'text-gray-400' : 'text-gray-200'}`} />
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-wrap justify-center gap-3 max-w-2xl">
              {['Policy Checking', 'Submission Intake'].map((prompt) => (
                <Button
                  key={prompt}
                  variant="outline"
                  size="sm"
                  onClick={() => handlePromptClick(prompt)}
                  className="bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-700 border border-gray-300 rounded-full text-sm transition-colors flex items-center space-x-1 px-4 py-2"
                >
                  <span>{prompt}</span>
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
              ))}
              <Button
                variant="outline"
                size="sm"
                onClick={toggleChatbar}
                className="bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-700 border border-gray-300 rounded-full text-sm transition-colors flex items-center space-x-1 px-4 py-2"
              >
                <span>Create your own</span>
                <ChevronRightIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
