import React, { useState, useEffect } from 'react';
import { Button } from "./ui/button";
import { X } from 'lucide-react';
import { Table } from './ui/table'; // Import Table component
import { FileViewer } from './FileViewer';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  previewFileName: string | null;
  previewContent: React.ReactNode | null;
  excelData: ExcelData | null;
  fileViewerData: {
    fileData: ArrayBuffer | string | (string | number)[][] | null;
    fileType: "excel" | "pdf" | "png" | null;
    fileName: string;
  } | null;
  className?: string;
}

type ExcelData = {
  sheets: {
    [sheetName: string]: {
      headers: string[];
      rows: (string | number)[][];
    };
  };
};

export function Sidebar({
  isOpen,
  onClose,
  previewFileName,
  previewContent,
  excelData,
  fileViewerData
}: SidebarProps) {

  const [activeSheet, setActiveSheet] = useState<string | null>(null);

  useEffect(() => {
    if (excelData && Object.keys(excelData.sheets).length > 0) {
      setActiveSheet(Object.keys(excelData.sheets)[0]);
    }
  }, [isOpen, excelData]);

  const renderExcelPreview = () => {

    if (!excelData || typeof excelData !== 'object') {
      return <div>Error: Invalid Excel data</div>;
    }

    const sheetNames = Object.keys(excelData.sheets);

    if (sheetNames.length === 0) {
      return <div>Error: No sheets found in Excel file</div>;
    }

    if (!activeSheet || !excelData.sheets[activeSheet]) {
      setActiveSheet(sheetNames[0]);
    }

    const currentSheet = excelData.sheets[activeSheet || sheetNames[0]];

    return (
      <div className="w-full overflow-x-auto">
        <div className="mb-4 flex space-x-2 overflow-x-auto pb-2">
          {sheetNames.map(sheetName => (
            <Button
              key={sheetName}
              variant={activeSheet === sheetName ? "default" : "outline"}
              onClick={() => setActiveSheet(sheetName)}
              className="whitespace-nowrap"
            >
              {sheetName}
            </Button>
          ))}
        </div>
        {currentSheet ? (
          <div className="overflow-x-auto">
            <Table className="w-full">
              <thead>
                <tr>
                  {currentSheet.headers.map((header, index) => (
                    <th key={index} className="px-4 py-2 border">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentSheet.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className="px-4 py-2 border">{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div>
            <h3>Raw Excel Data:</h3>
            <pre>{JSON.stringify(excelData, null, 2)}</pre>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-[40%] bg-background border-l border-border transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4 border-b border-border">
          <h2 className="text-lg font-semibold">{previewFileName || 'Preview'}</h2>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-5 w-5" />
          </Button>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          {fileViewerData ? (
            <FileViewer
              fileData={fileViewerData.fileData}
              fileType={fileViewerData.fileType}
              fileName={fileViewerData.fileName}
            />
          ) : excelData ? (
            renderExcelPreview()
          ) : previewContent ? (
            <div className="w-full bg-primary/5 rounded-xl p-4">
              {previewContent}
            </div>
          ) : (
            <div>No preview available</div>
          )}
        </div>
      </div>
    </div>
  );
}
