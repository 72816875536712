import { useState, useRef, useCallback, useMemo, useEffect } from "react"
import { Button } from "../ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { PhoneCall, Sparkle, Save, Search, Star, PhoneIncoming, PhoneOutgoing, ChevronLeft, ChevronRight, ChevronDown, ChevronUp } from "lucide-react"
import { AutoResizeTextarea } from "../AutoResizeTextArea"
import { HomeSidebarV1 } from "../HomeSidebarV1"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import { Input } from "../ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import { ArrowRight } from "lucide-react"
import { cn } from "../../lib/utils"
import { useNodeApi } from "../../hooks/useNodeApi"
import toast, { Toaster } from 'react-hot-toast'
import { CallLogResponse, GetUserSchemasResponse } from "../../services/api"
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
import { formatPhoneNumber } from '../../lib/phoneFormatter'
import { useNavigate } from 'react-router-dom'
import { withRequiredAuthInfo, UserClass } from "@propelauth/react";
import { usePolling } from "../../lib/usePolling";
import { POLLING_CONFIG } from "../../config/pollingConfig";
import { Label } from "../ui/label"

type SortConfig = {
  key: keyof VoiceData;
  direction: 'asc' | 'desc' | null;
};

type VoiceData = {
  id: number;
  name: string;
  context: string;
  agentPhoneNumber: string;
  userPhoneNumber: string;
  template: string;
  date: string;
  duration: string;
  status: string;
};

const StatusBadge = ({ status }: { status: string }) => {
  const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const statusConfig: Record<string, { color: string, bgColor: string, dotColor: string }> = {
    "Completed": { color: "text-green-700", bgColor: "bg-green-100", dotColor: "bg-green-500" },
    "Ended": { color: "text-green-700", bgColor: "bg-green-100", dotColor: "bg-green-500" },
    "Ongoing": { color: "text-orange-700", bgColor: "bg-orange-100", dotColor: "bg-orange-500" },
    "Initiated": { color: "text-orange-700", bgColor: "bg-orange-100", dotColor: "bg-orange-500" },
    "Voicemail": { color: "text-red-700", bgColor: "bg-red-100", dotColor: "bg-red-500" },
    "Not reachable": { color: "text-red-700", bgColor: "bg-red-100", dotColor: "bg-red-500" },
  };

  const config = statusConfig[capitalizedStatus] || statusConfig["Not reachable"];

  return (
    <div className={cn(
      "inline-flex items-center rounded-md px-2 py-1 text-sm font-medium",
      config.bgColor,
      config.color
    )}>
      <div
        className={cn(
          "w-2 h-2 rounded-full mr-2 flex-shrink-0",
          config.dotColor
        )}
        aria-hidden="true"
      ></div>
      {capitalizedStatus}
    </div>
  );
};

const validatePhoneNumber = (phoneNumber: string): string => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber, 'US');

    if (!parsedNumber || !isValidPhoneNumber(parsedNumber.number)) {
      throw new Error("Invalid phone number");
    }
    return parsedNumber.format('E.164');
  } catch (error) {
    throw new Error("Invalid phone number format");
  }
};

export const Voice = withRequiredAuthInfo(({ accessToken, userClass }: { accessToken: string | null, userClass: UserClass | null }) => {
  const topComponentRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate();

  const [templates] = useState([
    {
      name: "Upcoming Renewals",
      isFavorite: true,
      question: `Call Richard and ask if he is interested in getting his benefits renewed for this year. It's going to charge 20% more than last year.

Wait for them to respond.

Based on their response, you decide to let them know that the reason for the rate increase this year is because of the economy. If they find 20% as too expensive - you can also offer him to generate quotes from other carriers like Kaizer and Aetna.

If they insist, you can offer them a 5% discount. In any other case always say that you don't have permission to offer it and you want to talk to your manager.`
    },
    {
      name: "Policy Cancellations",
      isFavorite: false,
      question: "Please call Richard regarding the cancellation of his insurance policy. Explain that the reason for cancellation is that the risk no longer fits the underwriting guidelines, possibly due to changes in the company’s policies or after a detailed assessment. Discuss the implications of cancellation, including loss of coverage and potential consequences. Offer alternatives or solutions, such as adjusting coverage, and finding new policies."
    },
    {
      name: "Endorsement Confirmation",
      isFavorite: false,
      question: `Please call Richard to confirm the addition of a new car to his auto insurance policy. Before you give a confirmation, confirm his DOB is 13 April 1990. If he gives the right DOB, also confirm the policy number to be POL123456789

If the above matches, provide details of the addition of a new vehicle of the following type: Make: Toyota, Model: Camry, Year: 2022

Inform him that the effective date of the new coverage is the 1st of next month. Offer assistance with any questions he may have and discuss any necessary next steps.`
    },
    {
      name: "Missing Information Collection",
      isFavorite: false,
      question: "Please call Richard regarding his auto insurance policy. Begin by stating that you are calling about his auto policy. Confirm his identity by verifying his date of birth and policy number. Once his identity is confirmed, ask if there is anyone else who currently drives the car besides him. If he says yes, get their name, DOB, and any additional information required for book keeping & future claims."
    },
  ])
  const [promptText, setPromptText] = useState(templates.find(template => template.isFavorite)?.question || "")
  const [searchQuery, setSearchQuery] = useState("")
  const [callHistorySearchQuery, setCallHistorySearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'id', direction: null });

  const [agentPhoneNumber, setAgentPhoneNumber] = useState("")
  const [userPhoneNumber, setUserPhoneNumber] = useState("")

  const lastPollTimeRef = useRef(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [callLogs, setCallLogs] = useState<CallLogResponse[]>([]);

  const { makeCall, fetchCallLogs, isCallLoading, createTemplate, fetchUserSchemas } = useNodeApi(accessToken);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [lastSelectedTemplateName, setLastSelectedTemplateName] = useState<string>("Upcoming Renewals");

  const [newTemplateName, setNewTemplateName] = useState("");

  const [savedTemplates, setSavedTemplates] = useState<GetUserSchemasResponse[]>([]);

  const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false);

  const { poll: refreshCallLogs } = usePolling(
    () => {
      setIsHistoryLoading(true);
      return fetchCallLogs();
    },
    {
      interval: POLLING_CONFIG.INITIAL_INTERVAL,
      maxInterval: POLLING_CONFIG.MAX_INTERVAL,
      backoffMultiplier: POLLING_CONFIG.BACKOFF_MULTIPLIER,
      enabled: true,
      lastPollTimeRef,
      onSuccess: (logs) => {
        setCallLogs(logs);
        setIsHistoryLoading(false);
      },
      onError: (error) => {
        console.error("Failed to fetch call logs:", error);
        setIsHistoryLoading(false);
      }
    }
  );

  const showToast = (message: string, type: 'success' | 'error') => {
    toast.dismiss();
    if (type === 'success') {
      toast.success(message, {
        duration: 3000,
      });
    } else {
      toast.error(message, {
        duration: 5000,
      });
    }
  };

  const handleCallClick = async () => {
    try {
      if (!userPhoneNumber.trim()) {
        throw new Error("phone_number_missing");
      }

      const formattedPhoneNumber = validatePhoneNumber(userPhoneNumber);

      if (!agentPhoneNumber) {
        throw new Error("agent_number_missing");
      }
      if (!promptText.trim()) {
        throw new Error("prompt_missing");
      }

      await makeCall({
        user_phone_number: formattedPhoneNumber,
        agent_phone_number: agentPhoneNumber,
        user_prompt: promptText,
        template_used: lastSelectedTemplateName
      });

      showToast("Call initiated successfully", 'success');

      setIsHistoryLoading(true);

      await new Promise(resolve => setTimeout(resolve, 1500));

      try {
        const logs = await fetchCallLogs();
        setCallLogs(logs);
      } catch (error) {
        console.error("Initial fetch failed:", error);
      } finally {
        setIsHistoryLoading(false);
      }

      refreshCallLogs(true);

    } catch (err) {
      console.error("Failed to initiate call:", err);

      let errorMessage = "An unexpected error occurred. Please try again.";
      if (err instanceof Error) {
        switch (err.message) {
          case "phone_number_missing":
            errorMessage = "Please enter a phone number to call.";
            break;
          case "Invalid phone number":
          case "Invalid phone number format":
            errorMessage = "Please enter a valid phone number.";
            break;
          case "agent_number_missing":
            errorMessage = "Please select an agent phone number.";
            break;
          case "prompt_missing":
            errorMessage = "Please enter a prompt for the call.";
            break;
          default:
            if (err.message.includes("network")) {
              errorMessage = "Network error. Please check your internet connection and try again.";
            }
        }
      }

      showToast(errorMessage, 'error');
    }
  };

  const requestSort = useCallback((key: keyof VoiceData) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  }, []);

  const sortedAndFilteredData = useMemo(() => {
    return callLogs
      .map((log, index) => {
        const startDate = log.details ? new Date(log.details.start_timestamp) : null;
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return {
          id: log._id || `temp-id-${index}`,
          name: log.callee_name || "X",
          agentPhoneNumber: log.request?.agent_phone_number || "NA",
          userPhoneNumber: log.request?.user_phone_number || "NA",
          template: log.request?.template_used || "NA",
          date: startDate ? `${monthNames[startDate.getMonth()]} ${startDate.getDate()}` : "NA",
          duration: log.details ? `${Math.floor(Math.max(log.details.end_timestamp - log.details.start_timestamp, 0) / 1000)}s` : "NA",
          status: log.call_status || "NA"
        };
      })
      .filter((item) => {
        const searchLower = callHistorySearchQuery.toLowerCase();
        return (
          item.name.toLowerCase().includes(searchLower) ||
          item.agentPhoneNumber.includes(callHistorySearchQuery) ||
          item.userPhoneNumber.includes(callHistorySearchQuery) ||
          item.template.toLowerCase().includes(searchLower) ||
          item.date.toLowerCase().includes(searchLower) ||
          item.status.toLowerCase().includes(searchLower)
        );
      });
  }, [callLogs, callHistorySearchQuery]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className="border-y border-l border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-r border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  const SortableTableHead = ({ children, sortKey, className }: {
    children: React.ReactNode,
    sortKey: keyof VoiceData,
    className?: string
  }) => (
    <TableHead className={`border-y border-gray-200 ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -ml-4 px-4 h-full whitespace-nowrap"
        disabled={isHistoryLoading}
      >
        <span>{children}</span>
        {sortConfig.key === sortKey && (
          <span className="ml-2">
            {sortConfig.direction === 'asc' ?
              <ChevronUp className="h-4 w-4" /> :
              <ChevronDown className="h-4 w-4" />
            }
          </span>
        )}
      </Button>
    </TableHead>
  );

  const allTemplates = [
    ...templates,
    ...savedTemplates.map(schema => {
      const parsedSchema = JSON.parse(schema.json_schema);
      return {
        name: schema.name,
        question: parsedSchema.properties.prompt?.description || "",
        isFavorite: false
      };
    })
  ];

  const loadTemplate = (templateName: string) => {
    const selectedTemplate = allTemplates.find(template => template.name === templateName);
    if (selectedTemplate) {
      setPromptText(selectedTemplate.question);
      setLastSelectedTemplateName(templateName);
    }
  };

  const hasLoadedTemplates = useRef(false);

  useEffect(() => {
    const loadSavedTemplates = async () => {
      if (hasLoadedTemplates.current) return;
      hasLoadedTemplates.current = true;

      try {
        const schemas = await fetchUserSchemas("voice");
        setSavedTemplates(schemas);
      } catch (error) {
        console.error("Failed to load templates:", error);
        hasLoadedTemplates.current = false;
      }
    };

    loadSavedTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredTemplates = allTemplates
    .filter(template => template.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => (b.isFavorite ? 1 : 0) - (a.isFavorite ? 1 : 0));

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const handleCallHistorySearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCallHistorySearchQuery(e.target.value);
  };

  const columnWidths = {
    checkbox: "w-[60px]",
    name: "w-[140px]",
    fromNumber: "w-[140px]",
    toNumber: "w-[140px]",
    template: "w-[160px]",
    date: "w-[100px]",
    duration: "w-[100px]",
    status: "w-[140px]",
    actions: "w-[60px]"
  } as const;

  const handleSaveTemplate = async () => {
    try {
      if (!promptText.trim()) {
        throw new Error("prompt_empty");
      }
      if (!newTemplateName.trim()) {
        throw new Error("name_empty");
      }

      await createTemplate(
        newTemplateName,
        [
          {
            name: "prompt",
            type: "string",
            description: promptText
          }
        ],
        "voice"
      );

      const schemas = await fetchUserSchemas(
        "voice"
      );
      setSavedTemplates(schemas);

      showToast("Template saved successfully", "success");
      setNewTemplateName("");
      setIsSaveDropdownOpen(false);
    } catch (err) {
      console.error("Failed to save template:", err);

      let errorMessage = "Failed to save template. Please try again.";
      if (err instanceof Error) {
        switch (err.message) {
          case "prompt_empty":
            errorMessage = "Please enter a prompt before saving.";
            break;
          case "name_empty":
            errorMessage = "Please enter a name for the template.";
            break;
        }
      }
      showToast(errorMessage, "error");
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            background: '#363636',
            color: '#fff',
          },
        }}
      />
      <HomeSidebarV1 />
      <div className="flex-1 flex flex-col overflow-hidden ml-56">
        <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
          <div className="max-w-6xl mx-auto px-2">
            <div ref={topComponentRef}>
              <h1 className="text-xl font-semibold mb-4">Voice AI</h1>
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                  <div className="flex flex-wrap gap-4 mb-4">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="focus-visible:ring-0 focus:ring-0 focus:ring-offset-0"
                        >
                          <Sparkle className="h-4 w-4 mr-2" />
                          Voice Agents
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className="w-64 bg-white"
                        align="start"
                      >
                        <div className="p-2">
                          <div className="relative">
                            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                            <Input
                              type="text"
                              placeholder="Search for a prompt"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              className="pl-8 pr-2 py-1 w-full text-sm focus:ring-0"
                            />
                          </div>
                        </div>
                        {filteredTemplates.map((template) => (
                          <DropdownMenuItem
                            key={template.name}
                            onSelect={() => loadTemplate(template.name)}
                            className="flex items-center justify-between"
                          >
                            <span>{template.name}</span>
                            {template.isFavorite && (
                              <Star className="h-4 w-4 text-gray-800" />
                            )}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <DropdownMenu open={isSaveDropdownOpen} onOpenChange={setIsSaveDropdownOpen}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                          className="focus-visible:ring-0 focus:ring-0 focus:ring-offset-0"
                          onClick={(e) => {
                            const org = userClass?.getOrgs()[0];
                            const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];
                            if (!isFurtherAI) {
                              e.preventDefault();
                              toast.error("Contact your admin to enable this feature.");
                            }
                          }}
                        >
                          <Save className="h-4 w-4 mr-2" />
                          Save template
                        </Button>
                      </DropdownMenuTrigger>

                      <DropdownMenuContent
                        className="w-[400px] p-4 bg-white"
                        align="start"
                      >
                        <div className="space-y-4">
                          <p className="text-sm text-gray-600">
                            This will save the current <span className="font-medium text-gray-900">template</span> state as a preset which you can access later or share with others.
                          </p>

                          <div className="space-y-2">
                            <Label className="text-sm">Name</Label>
                            <div className="flex gap-2">
                              <Input
                                value={newTemplateName}
                                onChange={(e) => setNewTemplateName(e.target.value)}
                                placeholder="Enter template name"
                                className="flex-1"
                              />
                              <Button
                                onClick={handleSaveTemplate}
                                className="bg-black text-white hover:bg-gray-800 px-4"
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <AutoResizeTextarea
                    value={promptText}
                    onChange={(e) => setPromptText(e.target.value)}
                    placeholder="Enter your prompt here"
                    className="mb-4 w-full"
                  />
                  <div className="border-t border-gray-200 -mx-4 -mb-4 px-4 py-4 bg-gray-50">
                    <div className="flex flex-wrap items-center justify-between gap-4">
                      <div className="flex flex-wrap items-center gap-4">
                        <div className="flex items-center space-x-2">
                          <span className="text-md font-medium text-gray-900 whitespace-nowrap flex items-center">
                            <PhoneIncoming className="h-6 w-6 mr-2 text-gray-500" strokeWidth={1.5} />
                            Use this number
                          </span>
                          <Select value={agentPhoneNumber} onValueChange={setAgentPhoneNumber}>
                            <SelectTrigger className="w-[180px] focus:ring-0 bg-white">
                              <SelectValue placeholder="Select a number" />
                            </SelectTrigger>
                            <SelectContent className="bg-white">
                              <SelectItem value="+18508087606">{formatPhoneNumber("+18508087606")}</SelectItem>
                              <SelectItem value="+12077212806">{formatPhoneNumber("+12077212806")}</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex items-center space-x-2">
                          <span className="text-md font-medium text-gray-900 whitespace-nowrap flex items-center">
                            <PhoneOutgoing className="h-6 w-6 mr-2 text-gray-500" strokeWidth={1.5} />
                            this number
                          </span>
                          <Input
                            type="text"
                            value={userPhoneNumber}
                            onChange={(e) => setUserPhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <Button
                        onClick={handleCallClick}
                        disabled={isCallLoading}
                        className={`bg-blue-50 border border-blue-100 hover:bg-blue-200 text-blue-900 rounded-md px-4 py-2 flex items-center`}
                      >
                        <PhoneCall className="h-4 w-4 mr-2" />
                        {isCallLoading ? "Calling..." : "Call"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-semibold">History</h1>
              </div>
              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                  <div className="flex justify-between items-center mb-4">
                    <div className="relative mr-2">
                      <Input
                        type="text"
                        placeholder="Search"
                        className="pl-8 pr-2 py-2 border border-gray-300 rounded-md focus:ring-0"
                        value={callHistorySearchQuery}
                        onChange={handleCallHistorySearchChange}
                      />
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    </div>
                    <div className="flex space-x-2">
                      <Button variant="outline" size="sm">
                        <span className="mr-2">Duration</span>
                        <ChevronDown className="h-4 w-4" />
                      </Button>
                      <Button variant="outline" size="sm">
                        View
                      </Button>
                    </div>
                  </div>
                  <div className="overflow-x-auto">
                    <Table className="border-collapse border border-gray-200 table-fixed w-full">
                      <TableHeader>
                        <TableRow>
                          <TableHead className={`border-y border-l border-gray-200 ${columnWidths.checkbox}`}>
                            {isHistoryLoading ? (
                              <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
                            ) : (
                              <Checkbox
                                checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                                onCheckedChange={handleSelectAllRows}
                              />
                            )}
                          </TableHead>
                          <SortableTableHead sortKey="name" className={columnWidths.name}>
                            Name
                          </SortableTableHead>
                          <SortableTableHead sortKey="agentPhoneNumber" className={columnWidths.fromNumber}>
                            From number
                          </SortableTableHead>
                          <SortableTableHead sortKey="userPhoneNumber" className={columnWidths.toNumber}>
                            To number
                          </SortableTableHead>
                          <SortableTableHead sortKey="template" className={columnWidths.template}>
                            Template
                          </SortableTableHead>
                          <SortableTableHead sortKey="date" className={columnWidths.date}>
                            Date
                          </SortableTableHead>
                          <SortableTableHead sortKey="duration" className={columnWidths.duration}>
                            Duration
                          </SortableTableHead>
                          <SortableTableHead sortKey="status" className={columnWidths.status}>
                            Status
                          </SortableTableHead>
                          <TableHead className={`border-y border-r border-gray-200 ${columnWidths.actions}`}></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {isHistoryLoading ? (
                          renderShimmerRows()
                        ) : (
                          paginatedData.map((row) => (
                            <TableRow
                              key={row.id}
                              onClick={() => navigate(`/voice/logs/${row.id}`)}
                              className="cursor-pointer hover:bg-gray-50"
                            >
                              <TableCell className={`pl-4 ${columnWidths.checkbox}`}>
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onCheckedChange={(checked) => handleRowSelection(row.id, checked as boolean)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </TableCell>
                              <TableCell className={`px-4 ${columnWidths.name}`}>
                                <div className="flex items-center">
                                  <span className="truncate">{row.name}</span>
                                </div>
                              </TableCell>
                              <TableCell className={`px-4 ${columnWidths.fromNumber}`}>
                                <span className="truncate">{formatPhoneNumber(row.agentPhoneNumber)}</span>
                              </TableCell>
                              <TableCell className={`px-4 ${columnWidths.toNumber}`}>
                                <span className="truncate">{formatPhoneNumber(row.userPhoneNumber)}</span>
                              </TableCell>
                              <TableCell className={`px-4 ${columnWidths.template}`}>
                                <span className="truncate">{row.template}</span>
                              </TableCell>
                              <TableCell className={`px-4 ${columnWidths.date}`}>{row.date}</TableCell>
                              <TableCell className={`px-4 ${columnWidths.duration}`}>{row.duration}</TableCell>
                              <TableCell className={`px-4 ${columnWidths.status}`}>
                                <StatusBadge status={row.status} />
                              </TableCell>
                              <TableCell className={`px-4 ${columnWidths.actions}`}>
                                <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                                  <ArrowRight className="h-4 w-4" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <p className="text-sm text-gray-500">
                      {isHistoryLoading
                        ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                        : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                      }
                    </p>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-gray-500">Rows per page</span>
                      <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                        <SelectTrigger className="w-[70px] focus:ring-0">
                          <SelectValue placeholder={rowsPerPage.toString()} />
                        </SelectTrigger>
                        <SelectContent className="bg-white">
                          <SelectItem value="5">5</SelectItem>
                          <SelectItem value="10">10</SelectItem>
                          <SelectItem value="20">20</SelectItem>
                        </SelectContent>
                      </Select>
                      <span className="text-sm text-gray-500">
                        Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                      </span>
                      <div className="flex space-x-1">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                          disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                        >
                          <ChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
