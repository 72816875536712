import { useRef, useState } from "react"
import { HomeSidebarV1 } from "../HomeSidebarV1"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { Search } from "lucide-react"
import { Card, CardContent, CardTitle } from "../ui/card"
import { getIntegrationIcon } from '../../lib/iconUtils';
import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"

const categories = {
  "CRM": ["Salesforce", "Microsoft CRM"],
  "Policy Admin & Claims": ["Guidewire", "Majesco", "Duck Creek"],
  "AMS": ["Applied Epic", "AMS360", "EZLynx"],
  "Carriers": ["Travelers", "Progressive", "Delos", "Bamboo"]
};

type CategoryType = keyof typeof categories | "all";

export function Integrations() {
  const topComponentRef = useRef<HTMLDivElement>(null)
  const [currentFilter, setCurrentFilter] = useState<CategoryType>("all");
  const [searchTerm, setSearchTerm] = useState("");

  const filteredApps = Object.entries(categories).reduce((acc, [category, apps]) => {
    const filteredCategoryApps = apps.filter(app =>
      app.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredCategoryApps.length > 0) {
      acc[category] = filteredCategoryApps;
    }
    return acc;
  }, {} as Record<string, string[]>);

  const renderApps = () => {
    if (currentFilter === "all") {
      return Object.entries(filteredApps).map(([category, apps]) => (
        <div key={category} className="mb-8">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">{category}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {apps.map((appName, index) => (
              <AppCard key={index} appName={appName} />
            ))}
          </div>
        </div>
      ));
    } else {
      const appsToRender = filteredApps[currentFilter] || [];
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {appsToRender.map((appName, index) => (
            <AppCard key={index} appName={appName} />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-50">
      <HomeSidebarV1 />
      <div className="flex-1 overflow-y-auto ml-56 py-8">
        <div className="max-w-6xl mx-auto px-2">
          <div ref={topComponentRef}>
            <h1 className="text-xl font-semibold mb-4">Integrations</h1>
            <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-8">
              <div className="p-6">
                <div className="flex items-center mb-6 space-x-2">
                  <div className="relative w-64">
                    <Input
                      type="text"
                      placeholder="Search application..."
                      className="pl-8 pr-4 py-2 w-full bg-white border-gray-300 text-gray-900 focus:ring-0 h-10"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                  <Select
                    defaultValue="all"
                    onValueChange={(value) => setCurrentFilter(value as CategoryType)}
                  >
                    <SelectTrigger className="w-[180px] h-10">
                      <SelectValue placeholder="Select Category" />
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                      <SelectItem value="all">All Categories</SelectItem>
                      {Object.keys(categories).map((category) => (
                        <SelectItem key={category} value={category}>
                          {category}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                {renderApps()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AppCard: React.FC<{ appName: string }> = ({ appName }) => (
  <Card className="group relative h-40 transition-shadow hover:shadow-md">
    <CardContent className="p-0 h-full flex flex-col justify-between">
      <div className="flex items-center justify-between p-3 pb-0">
        {typeof getIntegrationIcon(appName) === 'string' ? (
          <img
            src={getIntegrationIcon(appName) as string}
            alt={`${appName} icon`}
            className="w-16 h-16 object-contain"
          />
        ) : (
          React.createElement(getIntegrationIcon(appName), {
            className: "w-16 h-16",
            'aria-label': `${appName} icon`,
            style: {
              objectFit: 'contain',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }
          })
        )}
        <Button
          variant="outline"
          size="sm"
          className="text-xs mr-2 mt-2"
        >
          Connect
        </Button>
      </div>
      <CardTitle className="text-md font-medium p-3 pt-0">
        {appName}
      </CardTitle>
    </CardContent>
  </Card>
);
