import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog"
import { Button } from "./ui/button"
import { ArrowUpRight, FileText, Upload, Trash } from "lucide-react"
import { useRef, useState, useEffect, useMemo } from "react"
import { UserDocument, UserDocumentUploadRequest } from "../services/api"
import toast from "react-hot-toast"
import { ScrollArea } from "./ui/scroll-area"

interface UploadDialogProps {
  isOpen: boolean
  onClose: () => void
  onUploadComplete: (documents: UserDocument[]) => void
  uploadDocument: (file: File, request: UserDocumentUploadRequest) => Promise<{ user_document_id: string; sas_url: string }>
  deleteDocument: (documentId: string) => Promise<void>
  selectedTemplate: string
  acceptedFileTypes?: {
    mimeTypes: string[]
    errorMessage: string
  }
  category?: 'fill_excel' | 'compare' | string
}

const defaultAcceptedFileTypes = {
  mimeTypes: [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp'
  ],
  errorMessage: 'Please upload PDF or image files only.'
}

const getMimeTypeAcceptString = (mimeTypes: string[]): string => {
  const mimeToExtension: { [key: string]: string } = {
    'application/pdf': '.pdf',
    'application/vnd.ms-excel': '.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
    'image/jpeg': '.jpg,.jpeg',
    'image/png': '.png',
    'image/gif': '.gif',
    'image/webp': '.webp'
  };

  return mimeTypes
    .map(mime => `${mime},${mimeToExtension[mime] || ''}`)
    .filter(Boolean)
    .join(',');
};

export function UploadDialog({
  isOpen,
  onClose,
  onUploadComplete,
  uploadDocument,
  deleteDocument,
  acceptedFileTypes = defaultAcceptedFileTypes,
  category
}: UploadDialogProps) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadedDocuments, setUploadedDocuments] = useState<UserDocument[]>([])
  const [isUploading, setIsUploading] = useState(false)

  const acceptString = useMemo(() =>
    getMimeTypeAcceptString(acceptedFileTypes.mimeTypes),
    [acceptedFileTypes.mimeTypes]
  );

  useEffect(() => {
    if (!isOpen) {
      setUploadedDocuments([])
    }
  }, [isOpen])

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const droppedFiles = Array.from(e.dataTransfer.files)
    handleFiles(droppedFiles)
  }

  const removeFile = async (fileName: string) => {
    const documentToRemove = uploadedDocuments.find(doc => doc.filename === fileName);
    if (documentToRemove) {
      try {
        await deleteDocument(documentToRemove._id);
        setUploadedDocuments(prev => prev.filter(doc => doc._id !== documentToRemove._id));

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        toast.error(`Failed to delete document ${fileName}`);
      }
    }
  }

  const handleFiles = async (newFiles: File[]) => {
    const validFiles = Array.from(newFiles).filter(file => {
      const fileType = file.type.toLowerCase();
      return acceptedFileTypes.mimeTypes.includes(fileType);
    });

    setIsUploading(true)

    try {
      for (let i = 0; i < validFiles.length; i++) {
        const file = validFiles[i];
        const uploadRequest: UserDocumentUploadRequest = {
          filename: file.name,
          blob_url: "",
          origin: "upload",
          category: category || "",
          owner_uid: "",
          owner_oid: "",
          run_id: "",
        };

        const { user_document_id, sas_url } = await uploadDocument(file, uploadRequest);
        setUploadedDocuments(prev => [...prev, { _id: user_document_id, filename: file.name, blob_url: sas_url } as UserDocument]);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      toast.error('Failed to upload files');
    } finally {
      setIsUploading(false)
    }

    if (validFiles.length !== newFiles.length) {
      toast.error(acceptedFileTypes.errorMessage, {
        duration: 4000,
        icon: '⚠️',
      });
    }
  }

  const handleSave = () => {
    if (uploadedDocuments.length > 0) {
      onUploadComplete(uploadedDocuments);
      onClose();
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-lg w-[95vw] max-h-[90vh] min-h-[40vh] flex flex-col overflow-hidden p-0 rounded-lg">
        <DialogHeader className="flex-shrink-0 border-b border-gray-200 sticky top-0 bg-white z-10">
          <DialogTitle className="text-lg sm:text-xl font-semibold p-3 sm:p-4 pb-2 sm:pb-3">Upload Documents</DialogTitle>
        </DialogHeader>

        <div className="flex-shrink-0 -my-2 sm:-my-4 bg-gray-50">
          <div
            className="border-2 border-dashed border-gray-300 rounded-md p-4 sm:p-8 mx-2 sm:mx-4 my-4 sm:my-8 text-center cursor-pointer flex flex-col items-center justify-center"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
          >
            <Upload className="h-8 w-8 sm:h-10 sm:w-10 text-gray-400 mb-2 sm:mb-3" />
            <p className="text-sm sm:text-md font-medium text-gray-500">
              Drag &apos;n&apos; drop files here, or click to select files
            </p>
            <p className="mt-1 sm:mt-2 text-xs text-gray-500">
              You can upload up to 10 files.
            </p>
          </div>
        </div>

        {uploadedDocuments.length > 0 && (
          <div className="flex-1 overflow-hidden">
            <ScrollArea className="h-[25vh] sm:h-[35vh] rounded-md" type="always">
              <div className="px-2 sm:px-4 py-2 space-y-0">
                {uploadedDocuments.map((document) => (
                  <div
                    key={document._id}
                    className="flex items-center justify-between py-1 sm:py-2 px-2 hover:bg-gray-50 rounded-md"
                  >
                    <div className="flex items-center space-x-2 sm:space-x-3">
                      <div className="w-7 h-7 sm:w-8 sm:h-8 rounded-md bg-purple-50 flex items-center justify-center">
                        <FileText className="w-3 h-3 sm:w-4 sm:h-4 text-purple-600" />
                      </div>
                      <span className="text-xs sm:text-sm text-gray-900 truncate max-w-[180px] sm:max-w-[300px]">
                        {document.filename}
                      </span>
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => removeFile(document.filename)}
                      className="h-7 w-7 sm:h-8 sm:w-8 rounded-md group"
                    >
                      <Trash className="h-4 w-4 sm:h-4 sm:w-4 text-gray-400 group-hover:text-red-500 transition-colors" />
                    </Button>
                  </div>
                ))}
              </div>
            </ScrollArea>
          </div>
        )}

        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) => e.target.files && handleFiles(Array.from(e.target.files))}
          multiple
          accept={acceptString}
        />

        <DialogFooter className="flex-shrink-0 border-t border-gray-200 bg-white p-2 sm:p-3 sticky bottom-0 z-10">
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={onClose}
              className="text-gray-700 text-sm sm:text-base px-2 sm:px-4 rounded-md"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={uploadedDocuments.length === 0 || isUploading}
              variant="outline"
              className="border-blue-400 text-blue-700 text-sm sm:text-base bg-blue-50 hover:text-blue-700 px-2 sm:px-4 rounded-md"
            >
              <ArrowUpRight className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" />
              Save
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
