import React from "react";
import * as SubframeCore from "@subframe/core";
import { AiChatToolbar } from "./AiChatToolbar";

interface AiChatReceivedRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const AiChatReceivedRoot = React.forwardRef<
  HTMLElement,
  AiChatReceivedRootProps
>(function AiChatReceivedRoot(
  { children, className, ...otherProps }: AiChatReceivedRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "flex w-full items-start gap-4",
        className
      )}
      ref={ref as React.RefObject<HTMLDivElement>}
      {...otherProps}
    >
      <img
        className="h-8 w-8 flex-shrink-0"
        src="https://res.cloudinary.com/subframe/image/upload/v1725373220/uploads/299/qzjppixtznjz6ckkj1o9.png"
        alt="Further AI"
      />
      <div className="flex grow flex-col items-start -mt-1.5">
        {children ? (
          <div className="flex w-full flex-col items-start">
            {children}
          </div>
        ) : null}
        <AiChatToolbar />
      </div>
    </div>
  );
});

export const AiChatReceived = AiChatReceivedRoot;
