import { NodeInput } from '../services/api';
import { FieldConfig } from '../config/nodeTypeConfigs';

export function filterInputForBackend(input: NodeInput, fields: FieldConfig[]): NodeInput {
  return Object.fromEntries(
    Object.entries(input).filter(([key]) => {
      const field = fields.find(f => f.key === key);
      return field && !field.excludeFromBackend;
    })
  );
}
