export type FieldValue = string | number | boolean | null | SchemaDefinition | FileTemplate | Array<string | number | boolean | null | SchemaDefinition | FileTemplate> | Array<{input: string, data: string}>;

export interface SelectOption {
  value: string;
  label: string;
}

export interface MappingField {
    input: string;
    data: string;
}

export interface FileTemplate {
    filename: string;
    url: string;
}

export interface FieldConfig {
  type: 'input' | 'select' | 'json' | 'file' | 'mapping';
  label: string;
  key: string;
  options?: SelectOption[];
  readOnly?: boolean;
  valueMap?: Record<string, SchemaDefinition>;
  dependsOn?: string;
  excludeFromBackend?: boolean;
  accept?: string;
  fileTemplate?: FileTemplate;
}

export interface SchemaDefinition {
  title: string;
  description: string;
  type: string;
  properties: {
    [key: string]: {
      type: string;
      description: string;
      enum?: string[];
      items?: {
        type: string;
      };
    };
  };
  additionalProperties: boolean;
  required: string[];
}

export interface NodeTypeConfig {
  fields: FieldConfig[];
}

export type ExtractSchemaType = keyof typeof extractSchemas;

export const extractSchemas: Record<string, SchemaDefinition> = {
  policyType: {
    title: "PolicyType",
    description: "Extracts policy type from the email",
    type: "object",
    properties: {
      is_policy_type_present: {
        type: "boolean",
        description: "Whether the policy type is present in the text"
      },
      policy_type: {
        type: "string",
        enum: [
          "auto",
          "workers_comp",
          "commercial_package",
          "umbrella_or_excess_liability",
          "other"
        ],
        description: "The type of insurance policy mentioned"
      },
      thinking_step: {
        type: "array",
        items: {
          type: "string"
        },
        description: "Steps of reasoning to determine the policy type"
      }
    },
    additionalProperties: false,
    required: [
      "is_policy_type_present",
      "policy_type",
      "thinking_step"
    ]
  },
};

export const nodeTypeConfigs: Record<string, NodeTypeConfig> = {
  trigger: {
    fields: [
      { type: 'input', label: 'Email ID', key: 'emailId' },
      { type: 'input', label: 'Zapier URL', key: 'zapierUrl' },
      {
        type: 'select',
        label: 'Email Type',
        key: 'emailType',
        options: [
          { value: 'outlook', label: 'Outlook' },
          { value: 'gmail', label: 'Gmail' },
          { value: 'custom', label: 'Custom' },
        ],
      },
    ],
  },
  extract: {
    fields: [
      {
        type: 'select',
        label: 'Extract From',
        key: 'extractFrom',
        options: [
          { value: 'emailBody', label: 'Email Body' },
          { value: 'emailAttachments', label: 'Email Attachments' },
        ],
      },
      {
        type: 'select',
        label: 'Extract Schema',
        key: 'extractSchema',
        options: Object.keys(extractSchemas).map(key => ({ value: key, label: key })),
        valueMap: extractSchemas
      },
      {
        type: 'json',
        label: 'Extract Schema Details',
        key: 'extractSchemaDetails',
        readOnly: true,
        dependsOn: 'extractSchema',
        excludeFromBackend: true,
      },
    ],
  },
  verify: {
    fields: [
      { type: 'input', label: 'LHS', key: 'lhs' },
      {
        type: 'select',
        label: 'Operator',
        key: 'operator',
        options: [
          { value: '==', label: 'Equals' },
          { value: '!=', label: 'Not Equals' },
        ],
      },
      { type: 'input', label: 'RHS', key: 'rhs' },
    ],
  },
  fill: {
    fields: [
      {
        type: 'file',
        label: 'Excel File',
        key: 'template',
        accept: '.xlsx,.xls',
        fileTemplate: {
          filename: 'string',
          url: 'string',
        },
      },
      {
        type: 'select',
        label: 'Fill by',
        key: 'fill_spec_type',
        options: [
          { value: 'column', label: 'Column' },
          { value: 'row', label: 'Row' },
        ],
      },
      {
        type: 'mapping',
        label: 'Field Mapping',
        key: 'fill_specs',
        options: [
          { value: 'column1', label: 'Column 1' },
          { value: 'column2', label: 'Column 2' },
        ],
      },
    ],
  },
};
