import { useState, useMemo, useEffect, useCallback } from "react"
import { Button } from "../ui/button"
import { Search, ChevronUp, ChevronDown, ChevronLeft, ChevronRight, MoreHorizontal, User, Calendar, DollarSign, Plus, ExternalLink } from "lucide-react"
import { HomeSidebarV1 } from "../HomeSidebarV1"
import { Input } from "../ui/input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table"
import { Checkbox } from "../ui/checkbox"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select"
import { useNavigate } from 'react-router-dom'
import { withRequiredAuthInfo } from "@propelauth/react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../ui/dropdown-menu"
import { useNodeApi } from "../../hooks/useNodeApi";
import { SubmissionData } from "../../services/api";

type SubmissionEntry = {
  id: string;
  sender: string;
  dateProcessed: string;
  effectiveDate: string;
  submissionName: string;
  totalInsured: number;
  controlledBusiness: string;
  priceTarget: string;
  ingestionSpreadsheetUrl: string;
  lossRunSpreadsheetUrl: string;
};

export const Submissions = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState<{ key: keyof SubmissionEntry; direction: 'asc' | 'desc' | null }>({
    key: 'dateProcessed',
    direction: 'desc'
  });
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading] = useState(false);
  const [senders, setSenders] = useState<string[]>([]);
  const [selectedSenders, setSelectedSenders] = useState<string[]>([]);
  const { fetchSubmissions } = useNodeApi(accessToken);
  const [submissions, setSubmissions] = useState<SubmissionEntry[]>([]);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const columnWidths = {
    checkbox: "w-[30px]",
    sender: "w-[200px]",
    dateProcessed: "w-[150px]",
    effectiveDate: "w-[150px]",
    submissionName: "w-[150px]",
    totalInsured: "w-[120px]",
    controlledBusiness: "w-[120px]",
    priceTarget: "w-[120px] max-w-[120px]",
    ingestion: "w-[30px]",
    lossRun: "w-[30px]"
  } as const;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleRowSelection = (id: string, isChecked: boolean) => {
    setSelectedRows(prev =>
      isChecked
        ? [...prev, id]
        : prev.filter(rowId => rowId !== id)
    );
  };

  const handleSelectAllRows = (checked: boolean) => {
    if (checked) {
      const newSelectedRows = selectedRows.slice();
      paginatedData.forEach(row => {
        if (!newSelectedRows.includes(row.id)) {
          newSelectedRows.push(row.id);
        }
      });
      setSelectedRows(newSelectedRows);
    } else {
      const currentPageIds = paginatedData.map(row => row.id);
      setSelectedRows(selectedRows.filter(id => !currentPageIds.includes(id)));
    }
  };

  const requestSort = (key: keyof SubmissionEntry) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        if (currentConfig.direction === 'asc') {
          return { key, direction: 'desc' };
        }
        if (currentConfig.direction === 'desc') {
          return { key, direction: null };
        }
      }
      return { key, direction: 'asc' };
    });
  };

  const getSubmissions = useCallback(async () => {
    const result = await fetchSubmissions();
    const submissions = result.map((submission: SubmissionData) => {
      const totalInsured = parseInt(submission.data.total_insured_value || "");
      return {
        id: submission.submission_id,
        sender: submission.data.sender_name || "",
        dateProcessed: submission.data.date_processed || "",
        effectiveDate: submission.data.effective_date || "",
        submissionName: submission.data.submission_name || "",
        totalInsured: isNaN(totalInsured) ? 0 : totalInsured,
        controlledBusiness: submission.data.is_controlled_business_str || "Not found",
        priceTarget: submission.data.price_target || "Not found",
        ingestionSpreadsheetUrl: submission.data.ingestion_spreadsheet_path || "#",
        lossRunSpreadsheetUrl: submission.data.loss_run_spreadsheet_path || "#"
      }
    });
    return submissions;
  }, [fetchSubmissions]);

  useEffect(() => {
    let ignore = false;
    getSubmissions().then(result => {
      if (!ignore) {
        setSenders(Array.from(new Set(result.map(submission => submission.sender))));
        setSubmissions(result);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const sortedAndFilteredData = useMemo(() => {
    return submissions
      .filter((item) => {
        if (selectedSenders.length > 0 && !selectedSenders.includes(item.sender)) {
          return false;
        }

        const searchLower = searchQuery.toLowerCase();
        return (
          item.sender.toLowerCase().includes(searchLower) ||
          item.dateProcessed.toLowerCase().includes(searchLower) ||
          item.effectiveDate.toLowerCase().includes(searchLower) ||
          item.submissionName.toLowerCase().includes(searchLower) ||
          item.totalInsured.toString().includes(searchQuery) ||
          item.controlledBusiness.toLowerCase().includes(searchLower) ||
          item.priceTarget.toString().includes(searchQuery)
        );
      })
      .sort((a, b) => {
        if (sortConfig.direction === null) return 0;
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [submissions, searchQuery, sortConfig, selectedSenders]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return sortedAndFilteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [sortedAndFilteredData, currentPage, rowsPerPage]);

  const SortableTableHead = ({ children, sortKey, className }: { children: React.ReactNode, sortKey: keyof SubmissionEntry, className?: string }) => (
    <TableHead className={`border-y border-gray-200 ${className}`}>
      <Button
        variant="ghost"
        onClick={() => requestSort(sortKey)}
        className="hover:bg-transparent w-full justify-start -mx-4 px-1"
        disabled={isLoading}
      >
        {children}
        {sortConfig.key === sortKey && (
          sortConfig.direction === 'asc' ? <ChevronUp className="ml-2 h-4 w-4" /> : <ChevronDown className="ml-2 h-4 w-4" />
        )}
      </Button>
    </TableHead>
  );

  const handleRowClick = (submission: SubmissionEntry) => {
    navigate(`/submissions/${submission.id}`)
  }

  const renderShimmerRows = () => {
    return Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={`shimmer-${index}`}>
        <TableCell className="border-y border-l border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-24 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-12 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-20 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-gray-200">
          <div className="h-4 w-16 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
        <TableCell className="border-y border-r border-gray-200">
          <div className="h-4 w-4 bg-gray-200 rounded animate-pulse"></div>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <HomeSidebarV1 />
      <div className="flex-1 flex flex-col overflow-hidden ml-56">
        <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
          <div className="max-w-6xl mx-auto px-2">
            <div>
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-xl font-semibold">Submissions</h1>
                <div className="flex items-center gap-3">
                  <Button
                    variant="outline"
                    className="bg-white"
                  >
                    Preview workflow
                  </Button>
                  <Button
                    className="bg-black text-white hover:bg-black/90"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Create new submission
                  </Button>
                </div>
              </div>

              <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                  <div className="flex items-center space-x-2 mb-4">
                    <div className="relative">
                      <Input
                        type="text"
                        placeholder="Filter submissions..."
                        className="pl-8 pr-4 py-1 w-48 bg-white border-gray-300 text-gray-900 focus:ring-0 h-9"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                    </div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="outline"
                          size="sm"
                        >
                          <User className="h-4 w-4 mr-2" />
                          Sender
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56 bg-white p-2">
                        <div className="space-y-2">
                          <div className="relative">
                            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                            <Input
                              type="text"
                              placeholder="Search for a sender"
                              className="pl-8 w-full"
                            />
                          </div>
                          <div className="space-y-1">
                            {senders.map((sender) => (
                              <div
                                key={sender}
                                className="flex items-center space-x-2 px-1 py-1 hover:bg-gray-50 rounded cursor-pointer"
                                onClick={() => {
                                  setSelectedSenders(prev =>
                                    prev.includes(sender)
                                      ? prev.filter(s => s !== sender)
                                      : [...prev, sender]
                                  );
                                }}
                              >
                                <Checkbox
                                  checked={selectedSenders.includes(sender)}
                                  className="border-2 data-[state=checked]:bg-black data-[state=checked]:border-black"
                                />
                                <span className="text-sm">{sender}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <Button
                      variant="outline"
                      size="sm"
                    >
                      <Calendar className="h-4 w-4 mr-2" />
                      Date processed
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                    >
                      <DollarSign className="h-4 w-4 mr-2" />
                      Total insured
                    </Button>
                  </div>

                  <div className="overflow-x-auto">
                    <Table className="border-collapse border border-gray-200">
                      <TableHeader>
                        <TableRow>
                          <TableHead className="w-[60px] border-y border-l border-gray-200">
                            <Checkbox
                              checked={paginatedData.length > 0 && paginatedData.every(row => selectedRows.includes(row.id))}
                              onCheckedChange={handleSelectAllRows}
                            />
                          </TableHead>
                          <SortableTableHead sortKey="sender" className={columnWidths.sender}>Sender</SortableTableHead>
                          <SortableTableHead sortKey="dateProcessed" className={columnWidths.dateProcessed}>Date processed</SortableTableHead>
                          <SortableTableHead sortKey="effectiveDate" className={columnWidths.effectiveDate}>Effective date</SortableTableHead>
                          <SortableTableHead sortKey="submissionName" className={columnWidths.submissionName}>Submission name</SortableTableHead>
                          <SortableTableHead sortKey="totalInsured" className={columnWidths.totalInsured}>Total insured</SortableTableHead>
                          <SortableTableHead sortKey="controlledBusiness" className={columnWidths.controlledBusiness}>Controlled business</SortableTableHead>
                          <SortableTableHead sortKey="priceTarget" className={columnWidths.priceTarget}>Price target</SortableTableHead>
                          <SortableTableHead sortKey="ingestionSpreadsheetUrl" className={columnWidths.ingestion}>Ingestion</SortableTableHead>
                          <SortableTableHead sortKey="lossRunSpreadsheetUrl" className={columnWidths.lossRun}>Loss run</SortableTableHead>
                          <TableHead className="w-[40px] border-y border-r border-gray-200"></TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {isLoading ? (
                          renderShimmerRows()
                        ) : (
                          paginatedData.map((row) => (
                            <TableRow
                              key={row.id}
                              className="cursor-pointer hover:bg-gray-50"
                              onClick={() => handleRowClick(row)}
                            >
                              <TableCell className="pl-4">
                                <Checkbox
                                  checked={selectedRows.includes(row.id)}
                                  onCheckedChange={(checked) => handleRowSelection(row.id, checked as boolean)}
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </TableCell>
                              <TableCell className={columnWidths.sender}>{row.sender}</TableCell>
                              <TableCell className={columnWidths.dateProcessed}>{row.dateProcessed}</TableCell>
                              <TableCell className={columnWidths.effectiveDate}>{row.effectiveDate}</TableCell>
                              <TableCell className={columnWidths.submissionName}>{row.submissionName}</TableCell>
                              <TableCell className={columnWidths.totalInsured}>{currencyFormatter.format(row.totalInsured)}</TableCell>
                              <TableCell className={columnWidths.controlledBusiness}>{row.controlledBusiness}</TableCell>
                              <TableCell className={`${columnWidths.priceTarget} truncate ...`}>{row.priceTarget}</TableCell>
                              <TableCell className={columnWidths.ingestion}>
                                <a href={row.ingestionSpreadsheetUrl} target="_blank" rel="noopener noreferrer">
                                  <ExternalLink className="h-4 w-4" />
                                </a>
                              </TableCell>
                              <TableCell className={columnWidths.lossRun}>
                                <a href={row.lossRunSpreadsheetUrl} target="_blank" rel="noopener noreferrer">
                                  <ExternalLink className="h-4 w-4" />
                                </a>
                              </TableCell>
                              <TableCell>
                                <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                                  <MoreHorizontal className="h-4 w-4" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </div>

                  <div className="flex justify-between items-center mt-4">
                    <p className="text-sm text-gray-500">
                      {isLoading
                        ? <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                        : `${selectedRows.length} of ${sortedAndFilteredData.length} row(s) selected.`
                      }
                    </p>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-gray-500">Rows per page</span>
                      <Select value={rowsPerPage.toString()} onValueChange={(value) => setRowsPerPage(Number(value))}>
                        <SelectTrigger className="w-[70px] focus:ring-0 bg-white">
                          <SelectValue placeholder={rowsPerPage.toString()} />
                        </SelectTrigger>
                        <SelectContent className="bg-white">
                          <SelectItem value="5">5</SelectItem>
                          <SelectItem value="10">10</SelectItem>
                          <SelectItem value="20">20</SelectItem>
                        </SelectContent>
                      </Select>
                      <span className="text-sm text-gray-500">
                        Page {currentPage} of {Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                      </span>
                      <div className="flex space-x-1">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(sortedAndFilteredData.length / rowsPerPage)))}
                          disabled={currentPage === Math.ceil(sortedAndFilteredData.length / rowsPerPage)}
                        >
                          <ChevronRight className="h-4 w-4" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});
