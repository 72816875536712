import React, { useRef, useEffect } from 'react';
import { X } from 'lucide-react';

interface DocumentPreviewDialogProps {
  isOpen: boolean;
  onClose: () => void;
  previewUrl: string;
  fileType: 'pdf' | 'image' | 'excel';
}

const DocumentPreviewDialog: React.FC<DocumentPreviewDialogProps> = ({ isOpen, onClose, previewUrl, fileType }) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const getPreviewContent = () => {
    switch (fileType) {
      case 'pdf':
        return (
          <iframe
            src={previewUrl + "#toolbar=0"}
            className="w-full h-full border-0 bg-white"
            title="PDF Preview"
          />
        );
      case 'image':
        return (
          <img
            src={previewUrl}
            alt="Document Preview"
            className="max-w-full max-h-full object-contain bg-white"
          />
        );
      case 'excel': {
        const encodedUrl = encodeURIComponent(previewUrl);
        const viewerUrl = encodedUrl.includes('docs.google.com')
          ? previewUrl
          : `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
        return (
          <iframe
            src={viewerUrl}
            className="w-full h-full border-0 bg-white"
            title="Excel Preview"
          />
        );
      }
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-30">
      <div
        ref={dialogRef}
        className="absolute inset-y-0 right-0 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-2/5 bg-white shadow-xl"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 z-10 text-gray-500 hover:text-gray-700 focus:outline-none transition-colors"
          aria-label="Close preview"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="w-full h-full relative">
          {getPreviewContent()}
        </div>
      </div>
    </div>
  );
};

export default DocumentPreviewDialog;