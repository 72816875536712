import { Button } from "./ui/button";
import { FileText, Sparkles, Voicemail, GitCompare, Table2, Library, LogIn, LogOut, MoreVertical, GitBranch } from "lucide-react";
import { Separator } from "../components/ui/separator";
import furtherAiLogo from '../assets/fai-logo.svg';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { User, withAuthInfo, useLogoutFunction, useRedirectFunctions, UserClass } from "@propelauth/react";
import toast from 'react-hot-toast';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import userDefaultImg from '../assets/avatars/user-img.png';

export const HomeSidebarV1 = withAuthInfo(({ isLoggedIn, user, userClass }: { isLoggedIn: boolean, user: User | null, userClass: UserClass | null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('');
  const { redirectToLoginPage } = useRedirectFunctions()
  const logoutFn = useLogoutFunction()
  const org = userClass?.getOrgs()[0];
  const isFurtherAI = org?.orgMetadata["6296ccd4-3aa5-4475-bb9b-4a005612990b_FAI"];

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    setActiveButton(path || 'playground');
  }, [location]);

  const getIconProps = (route: string) => ({
    size: 16,
    strokeWidth: 1.5,
    className: "mr-2",
    color: activeButton === route ? "#1d4ed8" : "#18181B"
  });

  const handleButtonClick = (route: string) => {
    if (route === 'integrations' && !isFurtherAI) {
      toast.error("Contact your admin to enable this feature.")
    } else {
      setActiveButton(route);
      navigate(`/${route}`);
    }
  };

  return (
    <div className="fixed top-0 left-0 flex flex-col h-screen w-56 bg-white border-r border-gray-200 overflow-y-auto overflow-x-hidden">
      <div className="p-4 my-4">
        <img
          src={furtherAiLogo}
          alt="Further AI"
          className="h-6 max-w-full"
          width={160}
          height={34}
        />
      </div>
      <nav className="flex-1 overflow-y-auto overflow-x-hidden">
        {isFurtherAI && (
          <div className="mb-6">
            <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">
              AI Assistants
            </h2>
            <ul className="space-y-1 px-2">
              <Button
                variant="ghost"
                className={`w-full justify-start ${activeButton === 'submissions'
                  ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                  : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                  }`}
                onClick={() => handleButtonClick('submissions')}
              >
                <FileText {...getIconProps('submissions')} />
                <span className="text-sm font-semibold">Submissions</span>
              </Button>
              <Button
                variant="ghost"
                className={`w-full justify-start ${activeButton === 'uw-audit'
                  ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                  : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                  }`}
                onClick={() => handleButtonClick('uw-audit')}
              >
                <GitBranch {...getIconProps('uw-audit')} />
                <span className="text-sm font-semibold">Underwriting Audit</span>
              </Button>
            </ul>
          </div>
        )}

        <div className="mb-6">
          <h2 className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">
            AI Tools
          </h2>
          <ul className="space-y-1 px-2">
            <Button
              variant="ghost"
              className={`w-full justify-start ${activeButton === 'playground'
                ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                }`}
              onClick={() => handleButtonClick('playground')}
            >
              <Sparkles {...getIconProps('playground')} />
              <span className="text-sm font-semibold">Q&A</span>
            </Button>
          <Button
              variant="ghost"
              className={`w-full justify-start ${activeButton === 'compare'
                ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                }`}
              onClick={() => handleButtonClick('compare')}
            >
              <GitCompare {...getIconProps('compare')} />
              <span className="text-sm font-semibold">Comparison AI</span>
            </Button>
            <Button
              variant="ghost"
              className={`w-full justify-start ${activeButton === 'excel'
                ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                }`}
              onClick={() => handleButtonClick('excel')}
            >
              <Table2 {...getIconProps('excel')} />
              <span className="text-sm font-semibold">Excel AI</span>
            </Button>
            <Button
              variant="ghost"
              className={`w-full justify-start ${activeButton === 'voice'
                ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                }`}
              onClick={() => handleButtonClick('voice')}
            >
              <Voicemail {...getIconProps('voice')} />
              <span className="text-sm font-semibold">Voice AI</span>
            </Button>
            <Button
              variant="ghost"
              className={`w-full justify-start ${activeButton === 'integrations'
                ? 'bg-gray-100 text-blue-700 hover:text-blue-700'
                : 'text-gray-700 hover:bg-gray-100 hover:text-gray-700'
                }`}
              onClick={() => handleButtonClick('integrations')}
            >
              <Library {...getIconProps('integrations')} />
              <span className="text-sm font-semibold">Integrations</span>
            </Button>
          </ul>
        </div>
      </nav>

      <Separator className="border-t border-gray-200" />
      {isLoggedIn && user && (
        <div className="px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <img
                src={userDefaultImg}
                alt={user.firstName}
                className="rounded-full"
                width={32}
                height={32}
              />
              <div className="flex flex-col items-start overflow-hidden">
                <span className="text-sm font-medium text-gray-900 truncate max-w-[120px]">
                  {user.firstName} {user.lastName}
                </span>
                <span className="text-xs text-gray-600 truncate max-w-[120px]">
                  {user.email}
                </span>
              </div>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="h-8 w-8 p-0 hover:bg-gray-100 rounded-full focus:ring-0 focus-visible:ring-0 focus:ring-offset-0"
                >
                  <MoreVertical className="h-4 w-4 text-gray-400" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="w-52 p-1 bg-white"
                align="end"
                alignOffset={-5}
                sideOffset={8}
                forceMount
              >
                <DropdownMenuItem
                  onClick={() => logoutFn(false)}
                  className="flex items-center px-2 py-2 text-sm text-red-600 hover:bg-red-50 hover:text-red-700 focus:bg-red-50 focus:text-red-700 rounded-md cursor-pointer"
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
      {!isLoggedIn && (
        <div className="px-2 py-1 mb-4">
          <Button
            variant="ghost"
            className="w-full justify-start text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            onClick={() => redirectToLoginPage()}
          >
            <LogIn {...getIconProps('login')} />
            <span className="text-sm font-medium text-gray-800">Login</span>
          </Button>
        </div>
      )}
    </div>
  );
});
