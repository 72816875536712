import { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNodeApi } from "../../hooks/useNodeApi";
import { SubmissionEmailData } from "../../services/api";
import { HomeSidebarV1 } from "../HomeSidebarV1";
import { ChevronRight, FileText } from "lucide-react";
import toast from 'react-hot-toast';
import { Card, CardContent } from "../ui/card";
import { withRequiredAuthInfo } from "@propelauth/react";
import axios from 'axios';

const POLL_INTERVAL = 5000;
const POLL_TIMEOUT = 300000;
export const SubmissionDetail = withRequiredAuthInfo(({ accessToken }: { accessToken: string | null }) => {
    const navigate = useNavigate();
    const { id: submissionId } = useParams<{ id: string }>();
    const { fetchSubmissionEmailById } = useNodeApi(accessToken);
    const [submissionEmail, setSubmissionEmail] = useState<SubmissionEmailData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isOffline, setIsOffline] = useState(false);
    const lastPollTimeRef = useRef(0);

    const loadCompareLog = useCallback(async (bypassTimeCheck = false) => {
        if (!submissionId) {
            setError('No comparison ID provided.');
            setIsLoading(false);
            return;
        }

        const now = Date.now();
        if (!bypassTimeCheck && now - lastPollTimeRef.current < POLL_INTERVAL) {
            return;
        }

        let retries = 0;
        const maxRetries = Math.ceil(POLL_TIMEOUT / POLL_INTERVAL);

        const attemptFetch = async (): Promise<void> => {
            try {
                const result = await fetchSubmissionEmailById(submissionId);
                if (result) {
                    setSubmissionEmail(result);
                    setIsLoading(false);
                } else if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    setError('Connection to server lost. Please check back later.');
                    setIsLoading(false);
                }
                lastPollTimeRef.current = now;
                if (isOffline) {
                    setIsOffline(false);
                    toast.success("Connection restored. Compare log updated.");
                }
            } catch (error) {
                console.error("Failed to fetch submission emails:", error);
                if (retries > 0 && axios.isAxiosError(error) && error.status === 404) {
                    navigate('/404');
                    return;
                }
                if (retries < maxRetries) {
                    retries++;
                    setTimeout(() => attemptFetch(), POLL_INTERVAL);
                } else {
                    if (!isOffline) {
                        setIsOffline(true);
                        toast.error("Failed to fetch submission emails. Please try again.");
                    }
                    setError('Failed to fetch submission emails. Please try again.');
                    setIsLoading(false);
                }
            }
        };

        attemptFetch();
    }, [submissionId, fetchSubmissionEmailById, isOffline, navigate]);

    const loadCompareLogRef = useRef(loadCompareLog);

    useEffect(() => {
        loadCompareLogRef.current = loadCompareLog;
    }, [loadCompareLog]);

    useEffect(() => {
        loadCompareLogRef.current(true);
    }, []);

    return (
        <div className="flex h-screen overflow-hidden">
            <HomeSidebarV1 />
            <div className="flex-1 flex flex-col overflow-hidden ml-56">
                <div className="flex-1 overflow-y-auto bg-gray-50 py-8">
                    <div className="max-w-6xl mx-auto px-2">
                        {isLoading ? (
                            <SubmissionDetailSkeleton />
                        ) : error ? (
                            <SubmissionDetailError error={error} />
                        ) : submissionEmail ? (
                            <SubmissionDetailResults submissionemail={submissionEmail} />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
})

function SubmissionDetailResults({ submissionemail }: { submissionemail: SubmissionEmailData }) {
    const navigate = useNavigate();

    return (
        <div>
            <div className="flex items-center mb-4">
                <div className="flex items-center text-md text-gray-500">
                    <span className="hover:text-gray-700 cursor-pointer" onClick={() => navigate('/submissions')}>Submissions</span>
                    <ChevronRight className="h-4 w-4 mx-2" />
                    <span className="font-medium text-gray-900">Email Conversation</span>
                </div>
            </div>
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
                <div className="p-4">
                    <div className="mb-6">
                        <h2 className="text-lg font-semibold mb-4">{submissionemail.title || "Untitled Email"}</h2>
                    </div>

                    <div>
                        <div className="space-y-4">
                            <Card key="ai_response" className="bg-gray-50 ml-0">
                                <CardContent className="p-3">
                                    <div className="flex items-center mb-1">
                                        <span className="text-sm font-semibold text-blue-600">
                                            Further AI
                                        </span>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: submissionemail.body_without_previous_email_html || "Email not available" }} className="text-gray-700" />
                                    <div className="flex flex-wrap gap-2 items-center">
                                        {submissionemail.output_email_attachment_blobs?.map((document) => (
                                            <div key={document.name} className="inline-flex items-center text-gray-900 border border-gray-300 rounded-md text-sm bg-white hover:bg-gray-50 transition-colors px-3 py-2 h-9 document-preview-link">
                                                <FileText className="h-4 w-4 mr-2" />
                                                {document.name}
                                            </div>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                            <Card key="ai_response" className="bg-gray-50 ml-auto">
                                <CardContent className="p-3">
                                    <div className="flex items-center mb-1">
                                        <span className="text-sm font-semibold text-green-600">
                                            {submissionemail.sender_name || "User"}
                                        </span>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: submissionemail.previous_email_body_html || "Email not available" }} className="text-gray-700" />
                                    <div className="flex flex-wrap gap-2 items-center">
                                        {submissionemail.previous_email_attachment_blobs?.map((document) => (
                                            <a key={document.name} href={document.url} target="_blank" rel="noopener noreferrer">
                                                <div className="inline-flex items-center text-gray-900 border border-gray-300 rounded-md text-sm bg-white hover:bg-gray-50 transition-colors px-3 py-2 h-9 document-preview-link">
                                                    <FileText className="h-4 w-4 mr-2" />
                                                    {document.name}
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SubmissionDetailSkeleton() {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <div className="h-8 w-64 bg-gray-200 rounded mb-6 animate-pulse"></div>
                <div className="h-64 w-full bg-gray-200 rounded animate-pulse"></div>
            </div>
        </div>
    );
}

function SubmissionDetailError({ error }: { error: string }) {
    return (
        <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-4">
            <div className="p-4">
                <h1 className="text-xl font-semibold mb-6">Error</h1>
                <p className="text-red-500 mb-4">{error}</p>
            </div>
        </div>
    );
}
