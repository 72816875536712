import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as SubframeCore from "@subframe/core";
import { AiChatSent } from "./subframe/components/AiChatSent";
import { AiChatReceived } from "./subframe/components/AiChatReceived";
import { Textarea } from "./ui/textarea";
import { SendIcon, PaperclipIcon, X, FileIcon } from 'lucide-react';
import chatSchema from '../schemas/chatSchema.json';
import { Sidebar } from './ChatSidebar';
import * as XLSX from 'xlsx';
import { ToggleSwitch } from "./ToggleSwitch";
import { PreviewNode } from './PreviewNode';
import { Connection, DroppedNode } from '../types/chat';

type MessageStatus = 'complete' | 'thinking' | 'typing';

type Message = {
  id: string;
  type: 'sent' | 'received';
  content: string;
  status: MessageStatus;
  attachments?: Attachment[];
  hasAttachments?: boolean;
  fileAnalysis?: FileAnalysis;
  fileName?: string;
  excelData?: ExcelData | null;
};

type Attachment = {
  id: string;
  name: string;
  url: string;
};

type FileAnalysis = {
  content: string;
  findings: { start: number; end: number; text: string }[];
};

type ExcelData = {
  sheets: {
    [sheetName: string]: {
      headers: string[];
      rows: (string | number)[][];
    };
  };
};

interface ActiveChatProps {
  initialMessage: string | null;
  onToggleDebugMode: () => void;
}

type FileViewerData = {
  fileData: ArrayBuffer | string | (string | number)[][] | null;
  fileType: "excel" | "pdf" | "png" | null;
  fileName: string;
};

const FilePreviewButton = ({ fileName, onClick, isPreviewOpen }: { fileName: string; onClick: () => void; isPreviewOpen: boolean }) => (
  <button
    onClick={onClick}
    className="mt-4 p-3 flex items-center bg-background border border-secondary rounded-lg hover:bg-secondary/10 transition-colors duration-200 w-full max-w-md"
  >
    <FileIcon className="h-6 w-6 text-primary mr-3" />
    <div className="text-left flex-grow">
      <div className="font-bold text-sm">{fileName}</div>
      <div className="text-xs text-muted-foreground">
        {isPreviewOpen ? 'Click to close' : 'Click to preview'}
      </div>
    </div>
  </button>
);

export function ActiveChat({ initialMessage, onToggleDebugMode }: ActiveChatProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isReading, setIsReading] = useState(false);
  const [isFileReading, setIsFileReading] = useState(false);
  const [animationDots, setAnimationDots] = useState('');
  const initialRenderRef = useRef(true);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPreviewFile, setCurrentPreviewFile] = useState<string | null>(null);
  const [excelData, setExcelData] = useState<ExcelData | null>(null);
  const [currentSchemaStep, setCurrentSchemaStep] = useState(0);
  const [isWaitingForUserInput, setIsWaitingForUserInput] = useState(false);
  const [isInitialMessageProcessed, setIsInitialMessageProcessed] = useState(false);
  const [fileViewerData, setFileViewerData] = useState<FileViewerData | null>(null);
  const [previewNodes, setPreviewNodes] = useState<DroppedNode[]>([]);
  const [connections, setConnections] = useState<Connection[]>([]);
  const [draggingConnection, setDraggingConnection] = useState<{ start: string; startSide: 'top' | 'right' | 'bottom' | 'left'; x: number; y: number } | null>(null);
  const [draggingNode, setDraggingNode] = useState<string | null>(null);
  const previewAreaRef = useRef<HTMLDivElement>(null);
  const [showPreview, setShowPreview] = useState(false);

  const animateTyping = useCallback((messageId: string, fullContent: string) => {
    let typedContent = '';
    const typingInterval = setInterval(() => {
      if (typedContent.length < fullContent.length) {
        typedContent += fullContent[typedContent.length];
        setMessages(prev => prev.map(msg =>
          msg.id === messageId ? { ...msg, content: typedContent } : msg
        ));
      } else {
        clearInterval(typingInterval);
        setMessages(prev => prev.map(msg =>
          msg.id === messageId ? { ...msg, status: 'complete' } : msg
        ));
      }
    }, 10);
  }, []);

  const generateAIResponse = useCallback(() => {
    let responses = '';
    let nextStep = currentSchemaStep;

    while (nextStep < chatSchema.length && chatSchema[nextStep].type === 'output') {
      responses += chatSchema[nextStep].content + '\n\n';
      nextStep++;
    }

    const lastMessage = messages[messages.length - 1];
    const hasAttachments = lastMessage && lastMessage.hasAttachments;

    let fileAnalysis: FileAnalysis | undefined;
    if (hasAttachments) {
      fileAnalysis = {
        content: "This is the content of the analyzed file...",
        findings: [
          { start: 5, end: 10, text: "important" },
          { start: 20, end: 30, text: "key finding" }
        ]
      };
    }

    if (responses) {
      const aiMessage: Message = {
        id: Date.now().toString(),
        type: 'received',
        content: '',
        status: 'typing',
        fileAnalysis
      };
      setMessages(prev => [...prev, aiMessage]);
      animateTyping(aiMessage.id, responses.trim());
    }

    setCurrentSchemaStep(nextStep);
  }, [currentSchemaStep, messages, animateTyping]);

  const processAIResponse = useCallback(() => {
    setIsReading(true);
    const dotsInterval = setInterval(() => {
      setAnimationDots(prev => (prev.length < 3 ? prev + '.' : ''));
    }, 300);

    const delay = isFileReading ? 3000 : 2000;

    setTimeout(() => {
      clearInterval(dotsInterval);
      setIsReading(false);
      setIsFileReading(false);
      setAnimationDots('');
      generateAIResponse();
    }, delay);
  }, [isFileReading, generateAIResponse, setIsReading, setIsFileReading, setAnimationDots]);

  useEffect(() => {
    if (initialRenderRef.current && initialMessage === 'Policy Checking' && !isInitialMessageProcessed) {
      handleInitialMessage(initialMessage);
      initialRenderRef.current = false;
      setIsInitialMessageProcessed(true);
    }
  }, [initialMessage, isInitialMessageProcessed]);

  useEffect(() => {
    if (currentSchemaStep < chatSchema.length && isInitialMessageProcessed) {
      const currentStepType = chatSchema[currentSchemaStep].type;
      if (currentStepType === 'output') {
        processAIResponse();
      } else if (currentStepType === 'input') {
        setIsWaitingForUserInput(true);
        setInputValue(chatSchema[currentSchemaStep].content || '');
      }
    }
  }, [currentSchemaStep, isInitialMessageProcessed, processAIResponse]);

  useEffect(() => {
    if (previewNodes.length > 0) {
      setShowPreview(true);
    } else {
      setShowPreview(false);
    }
  }, [previewNodes]);

  useEffect(() => {
    const handleMouseUpGlobal = () => {
      setDraggingNode(null);
      setDraggingConnection(null);
    };

    document.addEventListener('mouseup', handleMouseUpGlobal);
    return () => {
      document.removeEventListener('mouseup', handleMouseUpGlobal);
    };
  }, []);

  const handleInitialMessage = (content: string) => {
    const initialSentMessage: Message = {
      id: Date.now().toString(),
      type: 'sent',
      content,
      status: 'complete'
    };
    setMessages([initialSentMessage]);
    setCurrentSchemaStep(0);
  };

  const handleSend = (content: string) => {
    if (!content.trim() && attachments.length === 0) return;

    const newMessage: Message = {
      id: Date.now().toString(),
      type: 'sent',
      content,
      status: 'complete',
      attachments: attachments.length > 0 ? attachments : undefined,
      hasAttachments: attachments.length > 0,
      fileName: attachments[0]?.name,
      excelData: excelData,
    };

    setMessages(prev => [...prev, newMessage]);
    setInputValue('');
    setIsWaitingForUserInput(false);
    setCurrentSchemaStep(prev => prev + 1);

    if (attachments.length > 0) {
      setIsFileReading(true);
    }

    setAttachments([]);
    setExcelData(null);

    // If you still need to add preview nodes, you can do it directly without using chatCount
    // For example:
    if (previewNodes.length === 0) {
      setPreviewNodes([
        { id: 'pdf-preview', name: 'PDF Preview', iconName: 'FileText', x: 20, y: 20, isExpanded: false },
      ]);
    } else if (previewNodes.length === 1) {
      setPreviewNodes(prev => [
        ...prev,
        { id: 'json-response', name: 'JSON Response', iconName: 'Code', x: 20, y: 120, isExpanded: false },
      ]);
      setConnections([{ id: 'pdf-json', start: 'pdf-preview', end: 'json-response', startSide: 'bottom', endSide: 'top' }]);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (inputValue.trim()) {
        handleSend(inputValue);
      }
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const file = files[0];
      const fileType = file.type.split('/')[1];
      let fileData: ArrayBuffer | string | (string | number)[][] | null = null;
      let fileViewerType: "excel" | "pdf" | "png" | null = null;

      if (fileType === 'pdf') {
        fileData = await file.arrayBuffer();
        fileViewerType = 'pdf';
      } else if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg') {
        fileData = URL.createObjectURL(file);
        fileViewerType = 'png';
      } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        fileData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as (string | number)[][];
        fileViewerType = 'excel';
      }

      if (fileData) {
        setFileViewerData({
          fileData,
          fileType: fileViewerType,
          fileName: file.name,
        });
        setCurrentPreviewFile(file.name);
        setIsSidebarOpen(true);
      }

      const newAttachments: Attachment[] = Array.from(files).map(file => ({
        id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
        name: file.name,
        url: URL.createObjectURL(file)
      }));
      setAttachments(prev => [...prev, ...newAttachments]);

      const excelFile = Array.from(files).find(file =>
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel' ||
        file.name.endsWith('.xlsx') || file.name.endsWith('.xls')
      );

      if (excelFile) {
        try {
          const data = await excelFile.arrayBuffer();
          const workbook = XLSX.read(data);

          const excelData: ExcelData = { sheets: {} };
          workbook.SheetNames.forEach(sheetName => {
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            excelData.sheets[sheetName] = {
              headers: jsonData[0] as string[],
              rows: jsonData.slice(1) as (string | number)[][],
            };
          });

          setExcelData(excelData);
        } catch (error) {
          console.error('Error reading Excel file:', error);
        }
      }
    }
  };

  const removeAttachment = (id: string) => {
    setAttachments(prev => prev.filter(att => att.id !== id));
  };

  const handlePreviewClick = (fileName: string) => {
    if (currentPreviewFile === fileName) {
      setCurrentPreviewFile(null);
      setIsSidebarOpen(false);
    } else {
      setCurrentPreviewFile(fileName);
      setIsSidebarOpen(true);
    }
  };

  const renderPreviewContent = () => {
    const fileAnalysis = messages.find(m => m.fileName === currentPreviewFile)?.fileAnalysis;
    if (!fileAnalysis) return null;

    return (
      <pre className="whitespace-pre-wrap text-sm">
        {fileAnalysis.content}
      </pre>
    );
  };

  const toggleNodeExpansion = (id: string) => {
    setPreviewNodes(prev =>
      prev.map(node =>
        node.id === id ? { ...node, isExpanded: !node.isExpanded } : node
      )
    );
  };

  const handleNodeDragStart = (e: React.MouseEvent, nodeId: string) => {
    e.stopPropagation();
    setDraggingNode(nodeId);
  };

  const startConnection = (nodeId: string, side: 'top' | 'right' | 'bottom' | 'left', e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const rect = previewAreaRef.current?.getBoundingClientRect();
    if (rect) {
      setDraggingConnection({
        start: nodeId,
        startSide: side,
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const endConnection = (endNodeId: string, endSide: 'top' | 'right' | 'bottom' | 'left', e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (draggingConnection && draggingConnection.start !== endNodeId) {
      setConnections(prev => [
        ...prev,
        {
          id: `${draggingConnection.start}-${endNodeId}`,
          start: draggingConnection.start,
          end: endNodeId,
          startSide: draggingConnection.startSide,
          endSide: endSide,
        },
      ]);
      setDraggingConnection(null);
    }
  };

  const getConnectionPath = (conn: Connection): string => {
    const startNode = previewNodes.find(n => n.id === conn.start);
    const endNode = previewNodes.find(n => n.id === conn.end);
    if (!startNode || !endNode) return '';

    const startX = startNode.x + (conn.startSide === 'right' ? 200 : conn.startSide === 'left' ? 0 : 100);
    const startY = startNode.y + (conn.startSide === 'bottom' ? 80 : conn.startSide === 'top' ? 0 : 40);
    const endX = endNode.x + (conn.endSide === 'right' ? 200 : conn.endSide === 'left' ? 0 : 100);
    const endY = endNode.y + (conn.endSide === 'bottom' ? 80 : conn.endSide === 'top' ? 0 : 40);

    const midX = (startX + endX) / 2;

    return `M${startX},${startY} C${midX},${startY} ${midX},${endY} ${endX},${endY}`;
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (draggingNode) {
      const rect = previewAreaRef.current?.getBoundingClientRect();
      if (rect) {
        setPreviewNodes(prev =>
          prev.map(node =>
            node.id === draggingNode
              ? { ...node, x: e.clientX - rect.left, y: e.clientY - rect.top }
              : node
          )
        );
      }
    }
    if (draggingConnection) {
      const rect = previewAreaRef.current?.getBoundingClientRect();
      if (rect) {
        setDraggingConnection(prev => {
          if (!prev) return null;
          return {
            ...prev,
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
          };
        });
      }
    }
  };

  const removeNode = (id: string) => {
    setPreviewNodes(prev => prev.filter(node => node.id !== id));
    setConnections(prev => prev.filter(conn => conn.start !== id && conn.end !== id));
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className={`flex-1 flex flex-col ${showPreview ? 'w-2/3' : 'w-full'}`}>
        <header className="w-full bg-background">
          <div className="flex items-center justify-between px-4 py-6 max-w-[100rem] mx-auto">
            <div className="flex items-center text-sm">
              <SubframeCore.Icon
                className="text-black w-4 h-4 mr-2"
                name="FeatherWorkflow"
              />
              <span className="font-medium">AB-projects/<b>AutoFlow</b></span>
            </div>
            <ToggleSwitch
              isChecked={false}
              onToggle={onToggleDebugMode}
              label="Debug Mode"
            />
          </div>
        </header>

        <div className="flex flex-grow overflow-hidden">
          <div className={`flex-grow transition-all duration-300 ${isSidebarOpen ? 'w-[60%]' : 'w-full'}`}>
            <div className="h-full flex flex-col">
              <div className="flex-grow overflow-y-auto">
                <div className="max-w-3xl mx-auto px-4 py-4 space-y-6">
                  {messages.map((message) => (
                    <React.Fragment key={message.id}>
                      {message.type === 'sent' ? (
                        <AiChatSent avatar="https://res.cloudinary.com/subframe/image/upload/v1725373163/uploads/299/jvx36onorjl0q2mkxaoz.png">
                          <div className="bg-secondary text-secondary-foreground rounded-xl p-3 flex flex-col items-start min-h-[40px] max-w-[80%]">
                            <div>{message.content}</div>
                            {message.hasAttachments && (
                              <div className="mt-2 flex items-center text-xs text-muted-foreground">
                                <PaperclipIcon className="h-3 w-3 mr-1" />
                                {message.attachments?.length} {message.attachments?.length === 1 ? 'attachment' : 'attachments'}
                              </div>
                            )}
                          </div>
                        </AiChatSent>
                      ) : (
                        <AiChatReceived>
                          <div className="bg-primary/5 rounded-xl p-3 flex flex-col items-start min-h-[40px] max-w-[80%]">
                            <div className="whitespace-pre-wrap text-left">{message.content}</div>
                            {message.fileAnalysis && (
                              <FilePreviewButton
                                fileName={message.fileName || "Policy Comparison"}
                                onClick={() => handlePreviewClick(message.fileName || "Policy Comparison")}
                                isPreviewOpen={currentPreviewFile === (message.fileName || "Policy Comparison")}
                              />
                            )}
                          </div>
                        </AiChatReceived>
                      )}
                    </React.Fragment>
                  ))}
                  {isReading && (
                    <AiChatReceived>
                      <div className="bg-primary/5 rounded-xl p-3 flex items-center min-h-[40px] max-w-[80%]">
                        {isFileReading ? `Reading the file${animationDots}` : `Thinking${animationDots}`}
                      </div>
                    </AiChatReceived>
                  )}
                </div>
              </div>

              <div className="px-4 py-6 w-full">
                <div className="max-w-3xl mx-auto">
                  <div className="relative flex flex-col">
                    {attachments.length > 0 && (
                      <div className="mb-2 flex flex-wrap gap-2">
                        {attachments.map(att => (
                          <div key={att.id} className="bg-secondary text-secondary-foreground text-xs rounded-full px-3 py-1 flex items-center">
                            <PaperclipIcon className="h-3 w-3 mr-1" />
                            {att.name}
                            <button onClick={() => removeAttachment(att.id)} className="ml-2 text-primary hover:text-primary/80">
                              <X className="h-3 w-3" />
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="relative flex items-center">
                      <Textarea
                        ref={textareaRef}
                        placeholder="Type your message..."
                        className="w-full pr-24 py-2 text-base rounded-3xl placeholder-gray-400 border-2 border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/20 resize-none overflow-hidden min-h-[40px] max-h-[120px]"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        rows={1}
                        disabled={isReading || !isWaitingForUserInput}
                      />
                      <div className="absolute right-3 flex items-center space-x-2">
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileUpload}
                          className="hidden"
                          multiple
                          disabled={isReading}
                        />
                        <button
                          className={`p-2 hover:text-primary transition-colors duration-200 ${attachments.length > 0 ? 'text-primary' : 'text-muted-foreground'}`}
                          onClick={() => fileInputRef.current?.click()}
                          disabled={isReading}
                        >
                          <PaperclipIcon className="h-5 w-5" />
                        </button>
                        <button
                          className="p-2 hover:text-primary transition-colors duration-200"
                          onClick={() => handleSend(inputValue)}
                          disabled={(!inputValue.trim() && attachments.length === 0) || isReading}
                        >
                          <SendIcon className={`h-5 w-5 ${inputValue.trim() || attachments.length > 0 ? 'text-muted-foreground' : 'text-gray-200'}`} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isSidebarOpen && (
            <div className="w-[40%] transition-all duration-300">
              <Sidebar
                isOpen={isSidebarOpen}
                onClose={() => {
                  setIsSidebarOpen(false);
                  setCurrentPreviewFile(null);
                }}
                previewFileName={currentPreviewFile}
                previewContent={renderPreviewContent()}
                excelData={excelData}
                fileViewerData={fileViewerData}
              />
            </div>
          )}
        </div>
      </div>

      {/* Right side: Preview area */}
      {showPreview && (
        <div className="w-1/3 bg-white border-l border-gray-200 overflow-hidden">
          <div ref={previewAreaRef} className="h-full relative" onMouseMove={handleMouseMove}>
            <svg className="absolute top-0 left-0 w-full h-full pointer-events-none">
              {connections.map((conn) => (
                <path
                  key={conn.id}
                  d={getConnectionPath(conn)}
                  fill="none"
                  stroke="rgb(156 163 175)"
                  strokeWidth="2"
                />
              ))}
              {draggingConnection && (
                <path
                  d={(() => {
                    const startNode = previewNodes.find(n => n.id === draggingConnection.start);
                    if (!startNode) return '';

                    const startX = startNode.x + (draggingConnection.startSide === 'right' ? 200 : draggingConnection.startSide === 'left' ? 0 : 100);
                    const startY = startNode.y + (draggingConnection.startSide === 'bottom' ? 80 : draggingConnection.startSide === 'top' ? 0 : 40);

                    return `M${startX},${startY} L${draggingConnection.x},${draggingConnection.y}`;
                  })()}
                  fill="none"
                  stroke="rgb(156 163 175)"
                  strokeWidth="2"
                />
              )}
            </svg>
            {previewNodes.map((node) => (
              <PreviewNode
                key={node.id}
                node={node}
                onExpand={() => toggleNodeExpansion(node.id)}
                onClick={() =>  (node)}
                onRemove={() => removeNode(node.id)}
                onDragStart={(e) => handleNodeDragStart(e, node.id)}
                onStartConnection={startConnection}
                onEndConnection={endConnection}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
