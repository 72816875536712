import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogFooter } from "./ui/dialog";
import { Button } from "./ui/button";
import { WorkflowNodeData } from '../types/workflowTypes';
import { getWorkflowNodeIcon } from '../lib/iconUtils';
import { useNodeApi } from '../hooks/useNodeApi';
import { toast } from 'react-hot-toast';
import { NodeInput } from '../services/api';
import { FieldValue, nodeTypeConfigs } from '../config/nodeTypeConfigs';
import DynamicNodeFields from './DynamicNodeFields';
import { filterInputForBackend } from '../lib/nodeUtils';
import { SchemaDefinition } from '../config/nodeTypeConfigs';

interface NodeConfigDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  node: WorkflowNodeData | null;
  onSave: (updatedNode: WorkflowNodeData) => void;
  accessToken: string | null;
}

const NodeConfigDialog: React.FC<NodeConfigDialogProps> = ({ open, onOpenChange, node, accessToken }) => {
  const [input, setInput] = useState<NodeInput>({});
  const [type, setType] = useState(node?.type || '');

  const IconOrSrc = node ? getWorkflowNodeIcon(node.type) : null;

  const { saveNode, isLoading } = useNodeApi(accessToken);

  useEffect(() => {
    if (node) {
      setInput(node.input || {});
      setType(node.type || '');
    }
  }, [node]);

  const handleSave = async () => {
    if (node && nodeConfig) {
      try {
        const filteredInput = filterInputForBackend(input, nodeConfig.fields);
        await saveNode({
          type,
          input: filteredInput,
          updated_at: new Date().toISOString(),
        });
        // onSave({ ...node, ...updatedNode, title, input: filteredInput });
        onOpenChange(false);
        toast.success('Node saved successfully');
      } catch (err) {
        toast.error('Failed to save node');
      }
    }
  };

  const handleInputChange = (key: string, value: FieldValue) => {
    setInput((prevInput) => {
      const newInput = { ...prevInput, [key]: value };
      if (key === 'extractSchema') {
        newInput.extractSchemaDetails = value as SchemaDefinition;
      }
      return newInput;
    });
  };

  const nodeConfig = nodeTypeConfigs[type];

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] h-[80vh] flex flex-col">
        <div className="flex-grow overflow-y-auto py-6">
          <div className="grid gap-6">
            <div className="flex items-center space-x-4">
              <div className="w-16 h-16 flex items-center justify-center rounded-lg bg-gray-100 flex-shrink-0">
                {IconOrSrc && (typeof IconOrSrc === 'string' ? (
                  <img src={IconOrSrc} alt={node?.type} className="w-10 h-10 object-contain" />
                ) : (
                  <IconOrSrc className="w-10 h-10 text-gray-600" />
                ))}
              </div>
              <div>
                <h3 className="text-lg font-semibold">{node?.type}</h3>
                <p className="text-sm text-gray-500">Configure the settings for this node</p>
              </div>
            </div>
            <div className="space-y-4">
              {nodeConfig && (
                <DynamicNodeFields
                  fields={nodeConfig.fields}
                  values={input}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>
        </div>
        <DialogFooter className="pt-4 border-t">
          <Button onClick={handleSave} disabled={isLoading} className="w-full">
            {isLoading ? 'Saving...' : 'Save changes'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NodeConfigDialog;
